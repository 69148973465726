import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CenterInboxButton from "./CenterInboxButton";
import TelSection from "../../presentation/center/component/TelSection";

const CertificationInfo = ({ grade }) => {
    switch (grade) {
        case 5:
            return (
                <span className="text-success">5년 인증 우수 훈련기관</span>
            )
        case 3:
            return (
                <span>3년 인증 훈련기관</span>
            )
        case 1:
            return (
                <span>1년 인증 훈련기관</span>
            )
        default:
            return (
                <span>-</span>
            )
    }
}

const Ranking = ({ ranking }) => {

    // let textColor = "";
    // switch (ranking) {
    //     case 1:
    //         textColor = "gold";
    //         break;
    //     case 2:
    //         textColor = "silver";
    //         break;
    //     case 3:
    //         textColor = "brown";
    //         break;
    //     default:
    // }

    return (
        <>
            {
                !ranking ?
                    <></>
                    :
                    // ranking < 4 ?
                    //     <span style={{ color: textColor, fontSize: "1.3em" }} className="material-icons-outlined">
                    //         workspace_premium
                    //     </span>
                    //     :
                    <span className="app-small-font text-secondary">
                        [{ranking}위]
                    </span>
            }

        </>
    );
}

const LuckyScore = ({ scoreStd }) => {

    if (scoreStd > 10) {
        return (
            <span className="text-danger">주의</span>
        );
    } else if (scoreStd < 5) {
        return (
            <span className="text-success">양호</span>
        );
    } else {
        return (
            <span>보통</span>
        );
    }
}

const Score = ({ score }) => {
    // <span className={"" + centerData.score > 92 ? "text-success" : centerData.score < 84 ? "text-danger" : ""}>{(centerData.score / 20).toPrecision(3)}</span><span className="app-small-font text-secondary">점</span>

    if (!score) {
        return (
            <>
                <span>-</span>
                <span className="app-small-font text-secondary">점</span>
            </>
        );
    } else {
        if (score > 92) {
            return (
                <>
                    <span className="text-success">{(score / 20).toPrecision(3)}</span>
                    <span className="app-small-font text-secondary">점</span>
                </>
            );
        } else if (score < 84) {
            return (
                <>
                    <span className="text-danger">{(score / 20).toPrecision(3)}</span>
                    <span className="app-small-font text-secondary">점</span>
                </>
            );
        } else {
            return (
                <>
                    <span>{(score / 20).toPrecision(3)}</span>
                    <span className="app-small-font text-secondary">점</span>
                </>
            );
        }
    }
}

const AiRecommendScore = ({score, reviewCount}) => {

    if (reviewCount < 50 || !score) {
        return (
            <>
                <span>-</span>
                <span className="app-small-font text-secondary">%</span>
            </>
        );
    }

    let green = "";
    if(score > 0.67){
        green = "text-success";
    }

    return (
        <>
            <span className={`${green}`}>{(score*100).toPrecision(3)}</span>
            <span className="app-small-font text-secondary">%</span>
        </>
    );
}

const Teacher = ({ data }) => {
    return (
        <>
            <span>{data.name}</span>
            <span className="app-small-font text-secondary">({data.cnt}) </span>
        </>
    );
}

const CenterNcsList = ({ data, isHardSearch, searchNcsCode }) => {
    return (
        <>
            {
                searchNcsCode ?
                    searchNcsCode === data.ncs_code ?
                        <>
                            <span>{data.ncs_name}</span>
                            <span className="app-small-font">({data.cnt}) </span>
                        </>
                        :
                        <>
                            <span className="text-secondary"><del>{data.ncs_name}</del></span>
                            <span className="app-small-font text-secondary"><del>({data.cnt})</del> </span>
                        </>
                    :
                    <>
                        <span>{data.ncs_name}</span>
                        <span className="app-small-font text-secondary">({data.cnt}) </span>
                    </>
            }
        </>
    );
}

const CenterSummaryForDetail = ({ centerData, ranking, isHardSearch, searchNcsCode, hasViewButton }) => {



    const navigate = useNavigate();

    const teacherList = useMemo(() => centerData && centerData.teacherList
        .filter(item => item.cnt >= 5)
        .map(
            (item, idx) => {
                return <Teacher data={item} key={idx} />
            }
        ), [centerData]);

    const centerNcsList = useMemo(() => centerData && centerData.ncsList
        .map(
            (item, idx) => {
                return (
                    <CenterNcsList key={idx} data={item} isHardSearch={isHardSearch} searchNcsCode={searchNcsCode} />
                );
            }
        ), [centerData, isHardSearch, searchNcsCode]);




    if (!centerData) {
        return (
            <div className="col">
                <div className="row px-2 my-2">
                    <div className="col bg-white shadow rounded py-2">
                        <div className="row">
                            <div className="col text-secondary">로드에 실패하였습니다. 다시 시도해주세요.</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="col" onClick={() => navigate('/center/' + centerData.id)}>
                <div className="row px-2 my-2">
                    <div className="col bg-white shadow rounded py-2">
                        {
                            centerData.upcoming_lecture_cnt <= 0 &&
                            <div className="row">
                                <div className="col app-small-font text-danger">
                                    모집 예정 없음
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col fw-bold">
                                <Ranking ranking={ranking} /> {centerData.name}
                            </div>
                            <div className="col-auto" >
                                <CenterInboxButton centerId={centerData.id} />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col app-small-font text-secondary">
                                {centerData.address_base} {centerData.address_detail}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col app-small-font text-secondary">
                                tel.{centerData.tel}
                            </div>
                        </div>
                        {!hasViewButton &&
                            <TelSection telNumber={centerData.tel}></TelSection>
                        }


                        <div className="row mt-3">
                            <div className="col app-small-font text-secondary">과정 정보(최근 3년 기준)</div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">완료 </span>
                                <span>{centerData.round_cnt}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                            <div className="col">
                                <span className="app-small-font text-secondary">진행중 </span>
                                <span>{centerData.running_lecture_cnt}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                            <div className="col">
                                <span className="app-small-font text-secondary">모집중 </span>
                                <span>{centerData.upcoming_lecture_cnt}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                        </div>
                        <div className="row border-top mt-2 pt-2">
                            <div className="col app-small-font text-secondary">분석 결과</div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">평점 </span>
                                <Score score={centerData.score} />
                            </div>
                            <div className="col">
                                <span className="app-small-font text-secondary">강사 복불복 지수 </span>
                                <LuckyScore scoreStd={centerData.score_std} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">수강평 </span>
                                <span>{centerData.review_cnt}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                            <div className="col">
                                <span className="app-small-font text-secondary">수강평 AI 추천 </span>
                                <AiRecommendScore score={centerData.ai_recommend} reviewCount={centerData.review_cnt} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">담기 </span>
                                <span>{centerData.inbox_count}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                            <div className="col">
                                <span className="app-small-font text-secondary">보기 </span>
                                <span>{centerData.view_count}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">수료 인원 </span>
                                <span>1,845</span><span className="app-small-font text-secondary">명</span>
                            </div>
                            <div className="col">
                                <span className="app-small-font text-secondary">중도 탈락율 </span>
                                <span className="text-danger">13</span><span className="app-small-font text-secondary">%</span>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">인증 </span>
                                <CertificationInfo grade={centerData.rating_grade} />
                            </div>
                        </div>
                        <div className="row border-top mt-2">
                            <div className="col">
                                <span className="app-small-font text-secondary">ncs 분류(완료 강의 수)</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {centerNcsList}
                            </div>
                        </div>
                        <div className="row border-top mt-2">
                            <div className="col">
                                <span className="app-small-font text-secondary">주요 강사(후기 속 강사명 언급 수) </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {/* <span className="app-small-font placeholder placeholder-sm">안녕하세요qwer</span>
                                &nbsp;<span className="app-small-font placeholder placeholder-sm">안녕하세요</span>
                                &nbsp;<span className="app-small-font placeholder placeholder-sm">안녕하세요</span> */}
                                {teacherList}
                            </div>
                        </div>
                        {hasViewButton &&
                            <div className="row border-top mt-2 pt-2">
                                <div className="col d-grid">
                                    <button className="btn btn-primary">상세 보기</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default CenterSummaryForDetail;