const LoadingBar = () => {
    return (
        <>
            <div className="col placeholder-glow">
                <div className="row px-2 my-2">
                    <div className="col bg-white shadow rounded py-2">
                        <div className="card-body">
                            <h5 className="card-title placeholder-glow">
                                <span className="placeholder col-6"></span>
                            </h5>
                            <p className="card-text placeholder-glow">
                                <span className="placeholder col-7 me-1"></span>
                                <span className="placeholder col-4 me-1"></span>
                                <span className="placeholder col-4 me-1"></span>
                                <span className="placeholder col-6 me-1"></span>
                                <span className="placeholder col-8 me-1"></span>
                            </p>
                            <div className="btn btn-primary disabled placeholder col-6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoadingBar;
