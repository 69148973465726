import { useCallback, useEffect, useState } from "react";
import { deleteTeacherData, hasTeacherDataFromStorage, saveTeacherDataToStorage } from "../../common/util/teacherDataStorage";
import useTeacherService from "../hook/TeacherService";



const TeacherInboxButton = ({ name, centerId, centerName, tel, score, address }) => {

    const [isStored, setStored] = useState(false);

    // const { getCenter } = useCenterService();
    const { increaseInboxCount, decreaseInboxCount } = useTeacherService();

    const hasTeacher = useCallback((name, centerId) => hasTeacherDataFromStorage(name, centerId), []);

    const toggleTeacher = useCallback((name, centerId, centerName, tel, score, address) => {

        if (hasTeacherDataFromStorage(name, centerId)) {
            deleteTeacherData(name, centerId);
            setStored(hasTeacher(name, centerId));

            decreaseInboxCount(centerId, name);
        } else {

            saveTeacherDataToStorage({
                teacherName: name,
                centerId: centerId,
                centerName, tel, score, address
            });
            setStored(hasTeacher(name, centerId));

            increaseInboxCount(centerId, name);

            // 그러고 보면 왜 센터쪽은... 백엔드 연동 했지..?? 찜하기 수때문인가??
            // 먼가 이유가 있을텐데...

            // centerId로 백엔드 연동? - 이게 가장 편리할 것 같은데...
            // 마구잡이 클릭 예외처리 해야 되나... 귀찮...

            // getCenter(centerId)
            //     .then((body) => {
            //         saveCenterDataToStorage({ 
            //             centerId: centerId,
            //             name: body.data.centerInfo.name,
            //             address: body.data.centerInfo.address_base,
            //             tel: body.data.centerInfo.tel,
            //             score: body.data.centerInfo.score,
            //         });
            //         setStored(hasCenter(centerId));
            //     })
            //     .catch((response) => {
            //         alert("center 저장하기 통신 에러 발생")
            //     })
            //     ;
        }

    }, [hasTeacher, increaseInboxCount, decreaseInboxCount]);

    const handleClick = useCallback((e) => {
        e.stopPropagation(); // 부모 클릭 방지
        toggleTeacher(name, centerId, centerName, tel, score, address);
    }, [toggleTeacher, name, centerId, centerName, tel, score, address]);

    useEffect(() => {
        setStored(hasTeacher(name, centerId));
    }, [centerId, name, hasTeacher]);

    return (
        <>
            {isStored ?
                <i className={"material-icons-outlined align-bottom px-1 text-success"} onClick={handleClick}>
                    check
                </i>
                :
                <i className={"material-icons-outlined align-bottom px-1 text-secondary"} onClick={handleClick}>
                    archive
                </i>
            }
        </>
    );
}

export default TeacherInboxButton;