import { ResponsiveLine } from "@nivo/line";
import { useMemo } from "react";

const CourseRatingLineGraph = ({ loadStatus = {}, scoreList = [] }) => {

    const list = useMemo(() =>
        scoreList
            .map((e, index) => {
                return { "x": `${index + 1}회`, "y": e }
            })
        , [scoreList]);

    const data = [
        {
            "id": "japan",
            "color": "hsl(128, 70%, 50%)",
            // "color": "#3e76b7",
            "data": list,
            // "data": [
            //     {
            //         "x": "1회",
            //         "y": 4.5
            //     },
            //     {
            //         "x": "12회",
            //         "y": 4.4
            //     }
            // ]
        },
    ];

    return (
        <>
            <div className="row">
                <div className="col bg-white rounded shadow py-2">

                    {
                        loadStatus.error ?
                            <span>로드 실패하였습니다.</span>
                            : !loadStatus.isLoaded ?
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                list.length < 1 ?
                                <span>정보가 존재하지 않습니다</span> 
                                :
                                <>
                                    <div className="row mb-1">
                                        <div className="col text-center app-small-font fw-bold">최근 15회 수강 평점 변화</div>
                                    </div>
                                    <div className="row">
                                        <div className="col" style={{ height: '10em' }}>
                                            <ResponsiveLine
                                                data={data}
                                                margin={{ top: 30, right: 10, bottom: 30, left: 10 }}
                                                // layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
                                                layers={['grid', 'lines', 'axes', 'crosshair', 'mesh', 'points']}
                                                axisLeft={null}
                                                enablePointLabel={true}
                                                curve={"cardinal"}
                                                colors={['#3e76b7']}
                                                gridYValues={3}
                                                lineWidth={2}
                                                yScale={{
                                                    type: 'linear',
                                                    min: 3,
                                                    max: 5,
                                                    stacked: true,
                                                    reverse: false
                                                }}
                                                pointSize={3}
                                                pointColor={{ theme: 'background' }}
                                                pointBorderWidth={2}
                                                pointBorderColor={{ from: 'serieColor' }}
                                                // pointLabelYOffset={-12}
                                                // enablePointLabel={true}
                                                useMesh={true}
                                                enableCrosshair={false}
                                            // legends={[
                                            //     {
                                            //         anchor: 'bottom-right',
                                            //         direction: 'column',
                                            //         justify: false,
                                            //         translateX: 100,
                                            //         translateY: 0,
                                            //         itemsSpacing: 0,
                                            //         itemDirection: 'left-to-right',
                                            //         itemWidth: 80,
                                            //         itemHeight: 20,
                                            //         itemOpacity: 0.75,
                                            //         symbolSize: 12,
                                            //         symbolShape: 'circle',
                                            //         symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                            //         effects: [
                                            //             {
                                            //                 on: 'hover',
                                            //                 style: {
                                            //                     itemBackground: 'rgba(0, 0, 0, .03)',
                                            //                     itemOpacity: 1
                                            //                 }
                                            //             }
                                            //         ]
                                            //     }
                                            // ]}
                                            />
                                        </div>
                                    </div>
                                </>
                    }


                </div>
            </div>
        </>
    );
}

export default CourseRatingLineGraph;