import { useState, useCallback } from 'react';

const useInputs = (initialForm) => {
    const [form, setForm] = useState(initialForm);

    const onChange = useCallback(e => {

        //체크박스 대응
        if(e.target.type === 'checkbox'){
            const name = e.target.name;
            const value = e.target.checked;
            setForm(form => ({ ...form, [name]: value }));
        } else {
            const { name, value } = e.target;
            setForm(form => ({ ...form, [name]: value }));
        }

    }, []);

    const reset = useCallback(() => setForm(initialForm), [initialForm]);

    return [form, setForm, onChange, reset];
}

export default useInputs;