// import { useState } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import CenterSummaryForDetail from "../../../global/component/CenterSummaryForDetail";
import useCenterService from "../../../global/hook/CenterService";
import useEtcService from "../../../global/hook/EtcService,";
import useInputs from "../../../global/hook/useInputs";
import useSearchOptions from "../../../global/hook/useSearchOptions";
import LoadingBar from "../component/LoadingBar";
import NoSuchCenterNotice from "../component/NoSuchCenterNotice";



const CenterListPage = () => {
    // state
    const [{ ncsCode, address, centerName, isHardSearch, isRunningOnly }, setInputs, onChange] = useInputs({
        ncsCode: "",
        address: "",
        centerName: "",
        isHardSearch: true,
        isRunningOnly: false,
    });

    // console.log(isRunningOnly);

    const [centerListStatus, setCenterListStatus] = useState({ isLoaded: false, error: null });
    const [ncsCodeListStatus, setNcsCodeListStatus] = useState({ isLoaded: false, error: null });

    const [centerDatas, setCenterDatas] = useState([]);
    const [ncsDatas, setNcsDatas] = useState([]);

    // load hooks
    const { saveSearchOptions, loadSearchOptions } = useSearchOptions();

    // load hooks - 연동 service
    const { getCenterList } = useCenterService();
    const { getNcsList } = useEtcService();

    // inner methods - 가볍게만 사용 할 것

    const refreshCenterList = useCallback(() => {
        setCenterListStatus({ isLoaded: false, error: null });

        getCenterList(loadSearchOptions())
            .then((body) => {
                setCenterDatas(body.data.list);
                setCenterListStatus({ isLoaded: true });
            })
            .catch((response) => {
                setCenterListStatus({ error: response });
            });

    }, [getCenterList, loadSearchOptions]);

    const initNcsSelectBox = useCallback(() => {

        setNcsCodeListStatus({ isLoaded: false, error: null });
        getNcsList()
            .then((body) => {
                setNcsCodeListStatus({ isLoaded: true });
                setNcsDatas(body.data.list);
                const searchOptions = loadSearchOptions();

                setInputs(searchOptions);
            })
            .catch(response => {
                setNcsCodeListStatus({ error: response });
            });

    }, [loadSearchOptions, setInputs, getNcsList]);

    //event
    const handleOnClickSearchButton = useCallback((ncsCode, address, centerName, isHardSearch, isRunningOnly) => {
        saveSearchOptions({ ncsCode, address, centerName, isHardSearch, isRunningOnly });
        refreshCenterList();
    }, [saveSearchOptions, refreshCenterList]);

    const handleOnKeyPress = useCallback((event, ncsCode, address, centerName, isHardSearch, isRunningOnly, centerListStatus) => {
        if (event.key === 'Enter' && (centerListStatus.isLoaded || centerListStatus.error)) {
            saveSearchOptions({ ncsCode, address, centerName, isHardSearch, isRunningOnly });
            refreshCenterList();
        }
    }, [saveSearchOptions, refreshCenterList]);


    // effect
    useEffect(() => {
        initNcsSelectBox();
        refreshCenterList();

    }, [refreshCenterList, initNcsSelectBox]);

    // inner components - 가볍게만 사용 할 것
    const contentItems = useMemo(() => {

        if (centerDatas.length <= 0) {
            return <NoSuchCenterNotice />
        }

        return centerDatas.map(
            (item, idx) => {
                return <CenterSummaryForDetail ranking={idx + 1} centerData={item} isHardSearch={isHardSearch} searchNcsCode={ncsCode} hasViewButton={true} key={idx} />
            }

        );
    }, [centerDatas, isHardSearch, ncsCode]);

    const ncsOptions = useMemo(() => ncsDatas.map(
        (item, idx) => {
            return <option style={{ color: "#464444" }} value={item.ncs_code} key={idx}>{item.ncs_name} ({item.cnt}회)</option>
        }
    ), [ncsDatas]);

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            <select onChange={onChange} name="ncsCode" value={ncsCode} className={"form-select" + (ncsCode ? "" : " ncscode-not-selected")}>
                                                {
                                                    ncsCodeListStatus.error ?
                                                        <option>통신 실패</option>
                                                        :
                                                        !ncsCodeListStatus.isLoaded ?
                                                            <option>로딩중...</option>
                                                            :
                                                            <>
                                                                <option value="" className="ncscode-not-selected">ncs 직무 전체 (과정 수)</option>
                                                                {ncsOptions}
                                                            </>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col">
                                            <input onKeyUp={(event) => handleOnKeyPress(event, ncsCode, address, centerName, isHardSearch, isRunningOnly, centerListStatus)} maxLength={50} onChange={onChange} name="address" value={address} className="form-control" type="text" placeholder="지역 검색, 예)송파 분당 강남" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col">
                                            <input onKeyUp={(event) => handleOnKeyPress(event, ncsCode, address, centerName, isHardSearch, isRunningOnly, centerListStatus)} maxLength={50} onChange={onChange} name="centerName" value={centerName} className="form-control" type="text" placeholder="학원 검색, 예)네모 세모" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 d-grid ps-0">
                                    {
                                        centerListStatus.isLoaded || centerListStatus.error ?
                                            <button onClick={() => handleOnClickSearchButton(ncsCode, address, centerName, isHardSearch, isRunningOnly)} className="btn btn-primary">검색</button>
                                            :
                                            <button className="btn btn-primary" disabled>
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                    }
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    {
                                        centerListStatus.isLoaded || centerListStatus.error ?
                                            <div className="form-check form-switch">
                                                <input onChange={async (e) => {
                                                    onChange(e);
                                                    handleOnClickSearchButton(ncsCode, address, centerName, true, e.target.checked);
                                                }}
                                                    checked={isRunningOnly} name="isRunningOnly" className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                <label className="form-check-label text-secondary" htmlFor="flexSwitchCheckChecked">과정 운영중인 학원만 보기</label>
                                            </div>
                                            :
                                            <div className="form-check form-switch">
                                                <input checked={isRunningOnly} name="isRunningOnly" className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" disabled />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">과정 운영중인 학원만 보기</label>
                                            </div>
                                    }
                                </div>
                            </div>



                            {/* <div className="row mt-2">
                                <div className="col">
                                    {
                                        centerListStatus.isLoaded || centerListStatus.error ?
                                            <div className="form-check form-switch">
                                                <input onChange={async (e) => {
                                                    onChange(e);
                                                    handleOnClickSearchButton(ncsCode, address, centerName, e.target.checked);
                                                }}
                                                    checked={isHardSearch} name="isHardSearch" className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                <label className="form-check-label text-secondary" htmlFor="flexSwitchCheckChecked">선택된 ncs 직무만 계산해서 보기</label>
                                            </div>
                                            :
                                            <div className="form-check form-switch">
                                                <input checked={isHardSearch} name="isHardSearch" className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" disabled />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">선택된 ncs 직무만 계산해서 보기</label>
                                            </div>
                                    }
                                </div>
                            </div> */}

                            <div className="row mt-4">
                                <h1 className="col fs-4 fw-bold text-primary">국비 훈련기관(학원) 목록</h1>
                                {/* <div className="col">
                                    <select className="form-select form-select-sm">
                                        <option>종합 평가 정렬</option>
                                        <option>평점순 정렬</option>
                                        <option>개강 횟수 정렬</option>
                                    </select>
                                </div> */}
                            </div>

                            <div className="row">
                                <div className="col app-small-font text-secondary">최대 50개의 기관만 보입니다</div>
                            </div>

                            {/* 훈련 기관 리스트 */}
                            <div className="row p-2 row-cols-1 row-cols-md-2 row-cols-xl-3">
                                {/* 기관 섹션 시작 */}
                                {
                                    centerListStatus.error ?
                                        <div className="col">서버와 통신에 실패하였습니다. 다시 시도해주시기 바랍니다.</div>
                                        :
                                        !centerListStatus.isLoaded ?
                                            <LoadingBar />
                                            :
                                            contentItems
                                }
                            </div>


                        </div>
                    </div>

                    <pre>

                    </pre>

                </div>
            </div>

        </>
    )
}

export default CenterListPage;