import { useCallback } from "react";
import useRequest from "./AppRequest";

const useRoundService = () => {
    const { get } = useRequest();

    const getReviewList = useCallback((id, payload = null) => {
        return get(`api/v1/user/round/${id}/reviewList`, payload);
    }, [get]);

    return { getReviewList };
}

export default useRoundService;