import { useEffect, useState } from "react";
import SectionHeader from "../../../global/component/SectionHeader";
import useCenterService from "../../../global/hook/CenterService";
import CourseRatingHistogram from "./CourseRatingHistogram";

const FortuneAnalysisSection = ({centerId, ncsCode}) => {

    const [scoreList, setScoreList] = useState([]);
    const {getReviewScoreAll} = useCenterService();

    const [scoreListStatus, setScoreListStatus] = useState({ isLoaded: false, error: null });


    useEffect(() => {

        setScoreListStatus({ isLoaded: false, error: null });

        getReviewScoreAll(centerId, {ncsCode})
            .then(body => {
                setScoreList(body.data.list);
                setScoreListStatus({ isLoaded: true });
            })
            .catch(response => {
                setScoreListStatus({ error: response });
            });     

    }, [getReviewScoreAll, centerId, ncsCode]);


    return (
        <>
            <SectionHeader title="수강 평점 분포" subTitle="막대가 오른쪽으로 그리고 한쪽으로 몰릴수록 안정적입니다" />
            <div className="row px-2">
                <div className="col">
                    <CourseRatingHistogram loadStatus={scoreListStatus} scoreList={scoreList}></CourseRatingHistogram>
                </div>
            </div>
        </>
    );
}

export default FortuneAnalysisSection;