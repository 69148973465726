import { useCallback } from "react";
import useRequest from "./AppRequest";

const useCenterService = () => {

    const { get } = useRequest();

    const getCenterList = useCallback((payload = null) => {
        return get("api/v1/user/center/list", payload);
    }, [get]);

    const getCenter = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/summary`, payload);
    }, [get]);

    const getLatestReviewScore = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/latestReviewScore`, payload);
    }, [get]);

    const getReviewScoreAll = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/reviewScoreAll`, payload);
    }, [get]);

    const getNcsListByCenterId = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/ncsList`, payload);
    }, [get]);

    const getLatestCourseRoundList = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/latestCourseRoundList`, payload);
    }, [get]);

    const getSentimentScore = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/sentimentScore`, payload);
    }, [get]);

    const getTeacherList = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/teacherList`, payload);
    }, [get]);

    const getLongestReview = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/longestReviewList`, payload);
    }, [get]);

    const getLongestNegativeReview = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/longestNegativeReviewList`, payload);
    }, [get]);

    const getUpcomingLectureList = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/upcomingLectureList`, payload);
    }, [get]);

    const increaseViewCount = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/increaseViewCount`, payload);
    }, [get]);

    const increaseInboxCount = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/increaseInboxCount`, payload);
    }, [get]);

    const decreaseInboxCount = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/decreaseInboxCount`, payload);
    }, [get]);


    const getMinimumInfo = useCallback((id, payload = null) => {
        return get(`api/v1/user/center/${id}/minimumInfo`, payload);
    }, [get]);

    return {
        getCenterList,
        getCenter,
        getLatestReviewScore,
        getReviewScoreAll,
        getNcsListByCenterId,
        getLatestCourseRoundList,
        getSentimentScore,
        getTeacherList,
        getLongestReview,
        getUpcomingLectureList,
        getLongestNegativeReview,
        increaseViewCount,
        increaseInboxCount,
        decreaseInboxCount,
        getMinimumInfo,
    };
}

export default useCenterService;