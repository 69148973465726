import TopGuideNavigation from "./TopGuideNavigation";


const Top = () => {
    const topStyle = {
        backgroundColor: '#569cd6',
        color: 'white'
    };

    return (
        <div style={topStyle}>
        <div className="container-xxl">
            <div className="app-wrapper-top row">
                <div className="col">
                    <div className="row">
                        <div className="col fs-3 fw-bold text-start" style={{ fontFamily: "gugi" }}>직리니서처</div>
                    </div>
                    <TopGuideNavigation></TopGuideNavigation>
                </div>
                <div className="col-auto app-small-font text-end">
                    <div className="row">
                        <div className="col pt-1 pe-2">
                            Crawler Status
                            <span style={{ color: "#00ff00" }}> 정상</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col pe-2">
                            AI Analyzer Status
                            <span style={{ color: "#00ff00" }}> 정상</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Top;