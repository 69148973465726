import { useCallback, useEffect, useState } from "react";
import { deleteCenterData, hasCenterDataFromStorage, saveCenterDataToStorage } from "../../common/util/centerDataStorage";
import useCenterService from "../hook/CenterService";



const CenterInboxButton = ({ centerId }) => {

    const [isStored, setStored] = useState(false);

    const { getCenter, increaseInboxCount, decreaseInboxCount } = useCenterService();

    const hasCenter = useCallback(centerId => hasCenterDataFromStorage(centerId), []);

    const toggleCenter = useCallback((centerId) => {

        if (hasCenterDataFromStorage(centerId)) {
            deleteCenterData(centerId);
            setStored(hasCenter(centerId));

            decreaseInboxCount(centerId);
        } else {
            // centerId로 백엔드 연동? - 이게 가장 편리할 것 같은데...
            // 마구잡이 클릭 예외처리 해야 되나... 귀찮...

            getCenter(centerId)
                .then((body) => {
                    saveCenterDataToStorage({
                        centerId: centerId,
                        name: body.data.centerInfo.name,
                        address: body.data.centerInfo.address_base,
                        tel: body.data.centerInfo.tel,
                        score: body.data.centerInfo.score,
                    });
                    setStored(hasCenter(centerId));
                })
                .catch((response) => {
                    alert("center 저장하기 통신 에러 발생")
                })
                ;

            increaseInboxCount(centerId);
        }


    }, [getCenter, hasCenter, increaseInboxCount, decreaseInboxCount]);

    const handleClick = useCallback((e) => {
        e.stopPropagation(); // 부모 클릭 방지
        toggleCenter(centerId);
    }, [toggleCenter, centerId]);

    useEffect(() => {
        setStored(hasCenter(centerId));
    }, [centerId, hasCenter]);

    return (
        <>
            {isStored ?
                <i className={"material-icons-outlined align-bottom px-1 text-success"} onClick={handleClick}>
                    check
                </i>
                :
                <i className={"material-icons-outlined align-bottom px-1 text-secondary"} onClick={handleClick}>
                    archive
                </i>
            }
        </>
    );
}

export default CenterInboxButton;