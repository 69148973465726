import { useCallback, useMemo, useState } from "react"
import ReactModal from "react-modal";
import LoadingSpinner from "../../../global/component/LoadingSpinner";
import useRoundService from "../../../global/hook/RoundService";

const CourseInfo = (props) => {
    return (
        <div className="row py-1 border-bottom text-white sticky-top" style={{ backgroundColor: "#999999" }}>
            <div className="col">
                <div className="row">
                    <div className="col fw-bold">
                        {props.title}
                        <span className="app-small-font fw-normal">{props.round}회차</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col app-small-font">
                        수료일: {props.endDate}
                    </div>
                    <div className="col app-small-font">
                        평점: {props.score}
                    </div>
                </div>

            </div>
        </div>
    );
}

const ReviewContent = ({ data }) => {

    let sentiment = "";
    let textColor = "";
    let score = "";

    switch (data.sentiment) {
        case "POSITIVE":
            sentiment = "긍정";
            textColor = "text-success";
            score = `${Math.floor(data.positive_score * 100)}%`
            break;
        case "NEGATIVE":
            sentiment = "부정"
            textColor = "text-danger";
            score = `${Math.floor(data.negative_score * 100)}%`
            break;
        case "MIXED":
            sentiment = "혼합";
            score = `${Math.floor(data.mixed_score * 100)}%`
            break;
        case "NEUTRAL":
            sentiment = "기타";
            break;
        default:
    }

    return (
        <div className="col">
            <div className="row py-1 border-bottom ">
                <div className={`col-auto ${textColor}`}>
                    <div className="row">
                        <div className="col">{sentiment}</div>
                    </div>
                    <div className="row">
                        <div className="col app-small-font text-secondary text-center">{score}</div>
                    </div>
                </div>
                <div className="col">
                    {data.text}
                </div>
            </div>
        </div>
    );

}

const useReviewModal = () => {

    const [isOpen, setOpen] = useState();
    const [reviewList, setReviewList] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState({ isLoaded: false, error: null });
    const [roundData, setRoundDate] = useState({});

    const { getReviewList } = useRoundService();



    const openModal = useCallback((roundId, roundData = {}) => {

        setRoundDate(roundData);

        setLoadingStatus({ isLoaded: false, error: null });
        getReviewList(roundId)
            .then((body) => {
                setReviewList(body.data.list);
                setLoadingStatus({ isLoaded: true });
            })
            .catch((response) => {
                setLoadingStatus({ error: response });
            });

        setOpen(true);
    }, [getReviewList]);

    const reviewContentList = useMemo(() => {
        return reviewList.map((item, idx) => {
            return <ReviewContent data={item} key={idx} />
        });
    }, [reviewList]);


    const ReviewModal = () => {
        return (
            <ReactModal
                isOpen={isOpen}
                appElement={document.getElementById("root")}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setOpen(false)}
                // closeTimeoutMS={200}
                className="p-0" //하아... 이넘이...컨텐트네... overlay였으면 바로 끝인데...
                // overlayClassName="container-xxl" //아니... 이건 뭐...왜 안되는겨;; 스타일 직접 지정하면 무시되나..


                style={
                    {
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.75)',
                            zIndex: 9999,
                            maxWidth: '1320px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        
                        },
                        content: {
                            position: 'absolute',
                            top: '1em',
                            left: '1em',
                            right: '1em',
                            bottom: '2em',
                            border: '1px solid #ccc',
                            background: '#fff',
                            overflow: 'auto',
                            WebkitOverflowScrolling: 'touch',
                            borderRadius: '4px',
                            outline: 'none',
                            padding: '20px',
                        }
                    }
                }
            >
                <div className="container-fluid">
                    <CourseInfo title={roundData.name} endDate={roundData.endDate} round={roundData.round} score={roundData.totalScore} />

                    <div className="row row-cols-1">
                        {
                            loadingStatus.error ?
                                <div className="col">서버와 통신에 실패하였습니다. 다시 시도해주시기 바랍니다.</div>
                                :
                                !loadingStatus.isLoaded ?
                                    <div className="col text-center mt-5">
                                        <LoadingSpinner />
                                    </div>
                                    :
                                    reviewContentList.length < 1 ?
                                        <div className="col">후기가 존재하지 않습니다.</div>
                                        :
                                        reviewContentList
                        }
                    </div>
                    <div className="row sticky-bottom bg-white py-1">
                        <div className="col d-grid">
                            <button className="btn btn-primary" onClick={() => setOpen(false)}>닫기</button>
                        </div>
                    </div>
                </div>
            </ReactModal >
        );
    }



    return { openModal, ReviewModal };

}

export default useReviewModal;