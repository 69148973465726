import { useEffect, useState } from "react";
import CenterSummaryForDetail from "../../../global/component/CenterSummaryForDetail";
import SectionHeader from "../../../global/component/SectionHeader";
import useCenterService from "../../../global/hook/CenterService";
import LoadingBar from "./LoadingBar";

const CenterBasicInformationSection = ({ centerId, ncsCode }) => {

    const { getCenter } = useCenterService();
    const [centerData, setCenterData] = useState(null);
    const [centerStatus, setCenterStatus] = useState({ isLoaded: false, error: null });

    useEffect(() => {
        setCenterStatus({ isLoaded: false, error: null });
        getCenter(centerId, {ncsCode})
            .then(body => {
                setCenterData(body.data.centerInfo);
                setCenterStatus({ isLoaded: true });
            })
            .catch(response => {
                setCenterStatus({ error: response });
            });
    }, [getCenter, centerId, ncsCode]);

    return (
        <>
            <SectionHeader title="훈련기관 기본 정보 요약" subTitle="한 눈에 학원 정보를 확인해보세요" />
            <div className="row">

                {
                    centerStatus.error ?
                        <div className="col">서버와 통신에 실패하였습니다. 다시 시도해주시기 바랍니다.</div>
                        :
                        !centerStatus.isLoaded ?
                            <LoadingBar />
                            :
                            <CenterSummaryForDetail centerData={centerData} isHardSearch={ncsCode ? true : false} searchNcsCode={ncsCode} />
                }
            </div>
        </>
    );
}

export default CenterBasicInformationSection;