import SectionHeader from "../../../global/component/SectionHeader";
import useNegativeReviewModal from "../hook/useNegativeReviewModal";

const NegativeReviewSection = ({ centerId, ncsCode }) => {

    const { openModal, ReviewModal } = useNegativeReviewModal();

    return (
        <>
            <SectionHeader title="불만족 후기들" subTitle="수료생들은 학원의 어떤부분에서 불만족하였을까요?" />
            <div className="row py-2">
                <div className="col d-grid">
                    <button className="btn btn-primary" onClick={() => openModal(centerId, ncsCode)}>후기 확인</button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ReviewModal />
                </div>
            </div>
        </>
    );
}

export default NegativeReviewSection;