import React from 'react'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // 다음 렌더링에서 폴백 UI가 보이도록 상태를 업데이트 합니다.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // 에러 리포팅 서비스에 에러를 기록할 수도 있습니다.
        //   logErrorToMyService(error, errorInfo);
        // console.log(error)
    }

    render() {
        
        if (this.state.hasError) {
            // 폴백 UI를 커스텀하여 렌더링할 수 있습니다.
            return (
                <>
                    <div className="row row-cols-1 mt-5">
                        <div className="col text-center">예기치못한 문제가 발생하였습니다.</div>
                        <div className="col text-center">지속적인 문제 발생시 관리자에게 문의 바랍니다.</div>
                        <div className="col d-grid py-4">
                            <button className='btn btn-primary' onClick={() => {window.location.href = "/center"}}>메인 화면으로</button>
                        </div>
                    </div>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;