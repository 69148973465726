import { ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";

const TeacherCountDonut = ({ teacherList = [] }) => {

    const data = useMemo(() => {
        return teacherList
            .filter((item, index) => {
                if(index > 7) return false;
                if(item.cnt < 3) return false;

                return true;
            })
            .map((item, idx) => {
                return {
                    "id": item.name,
                    "label": item.name,
                    "value": item.cnt,
                    "color": "hsl(138, 70%, 50%)"
                }
            })
    }, [teacherList]);

    // const data = [
    //     {
    //         "id": "이민규",
    //         "label": "이민규",
    //         "value": 55,
    //         "color": "hsl(138, 70%, 50%)"
    //     },
    //     {
    //         "id": "강태광",
    //         "label": "강태광",
    //         "value": 9,
    //         "color": "hsl(138, 70%, 50%)"
    //     },
    //     {
    //         "id": "안화수",
    //         "label": "안화수",
    //         "value": 7,
    //         "color": "hsl(138, 70%, 50%)"
    //     },
    //     {
    //         "id": "백승",
    //         "label": "백승",
    //         "value": 3,
    //         "color": "hsl(138, 70%, 50%)"
    //     },
    //     {
    //         "id": "노하은",
    //         "label": "노하은",
    //         "value": 1,
    //         "color": "hsl(138, 70%, 50%)"
    //     },
    // ]


    return (
        <>
            <div className="row">
                <div className="col bg-white py-2">
                    <div className="row mb-1">
                        <div className="col text-center app-small-font fw-bold">후기 내 강사명 언급 수</div>
                    </div>
                    <div className="row">
                        <div className="col" style={{ height: '15em' }}>

                            <ResponsivePie
                                data={data}
                                margin={{ top: 30, right: 40, bottom: 30, left: 40 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            0.2
                                        ]
                                    ]
                                }}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={{ from: 'color' }}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            2
                                        ]
                                    ]
                                }}
                            // defs={[
                            //     {
                            //         id: 'dots',
                            //         type: 'patternDots',
                            //         background: 'inherit',
                            //         color: 'rgba(255, 255, 255, 0.3)',
                            //         size: 4,
                            //         padding: 1,
                            //         stagger: true
                            //     },
                            //     {
                            //         id: 'lines',
                            //         type: 'patternLines',
                            //         background: 'inherit',
                            //         color: 'rgba(255, 255, 255, 0.3)',
                            //         rotation: -45,
                            //         lineWidth: 6,
                            //         spacing: 10
                            //     }
                            // ]}
                            // fill={[
                            //     {
                            //         match: {
                            //             id: 'ruby'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'c'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'go'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'python'
                            //         },
                            //         id: 'dots'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'scala'
                            //         },
                            //         id: 'lines'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'lisp'
                            //         },
                            //         id: 'lines'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'elixir'
                            //         },
                            //         id: 'lines'
                            //     },
                            //     {
                            //         match: {
                            //             id: 'javascript'
                            //         },
                            //         id: 'lines'
                            //     }
                            // ]}
                            // legends={[
                            //     {
                            //         anchor: 'bottom',
                            //         direction: 'row',
                            //         justify: false,
                            //         translateX: 0,
                            //         translateY: 56,
                            //         itemsSpacing: 0,
                            //         itemWidth: 100,
                            //         itemHeight: 18,
                            //         itemTextColor: '#999',
                            //         itemDirection: 'left-to-right',
                            //         itemOpacity: 1,
                            //         symbolSize: 18,
                            //         symbolShape: 'circle',
                            //         effects: [
                            //             {
                            //                 on: 'hover',
                            //                 style: {
                            //                     itemTextColor: '#000'
                            //                 }
                            //             }
                            //         ]
                            //     }
                            // ]}
                            />

                        </div>
                    </div>

                </div>
            </div>

        </>
    );

}

export default TeacherCountDonut;