import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import LoadingSpinner from "../../../global/component/LoadingSpinner";
import SectionHeader from "../../../global/component/SectionHeader";
import useTeacherService from "../../../global/hook/TeacherService";
import useReviewModal from "../../center/hook/useReviewModal";

const Teacher = ({ data }) => {
    return (
        <>
            <span>{data.name}</span>
            <span className="app-small-font text-secondary">({data.cnt}) </span>
        </>
    );
}

const Content = ({ data, openModal }) => {

    const teacherList = useMemo(() => {
        if (data.teacherList.length > 0) {
            return data.teacherList
                .map(
                    (item, idx) => {
                        return <Teacher data={item} key={idx} />
                    }
                )
        } else {
            return (<>-</>)
        }
    }, [data.teacherList])


    return (
        <>
            <div className="col py-1 border-bottom" onClick={() => openModal(data.id, { name: data.name, round: data.round, totalScore: data.total_avrg_score, endDate: data.end_date })}>
                <div className="row">
                    <div className="col-auto d-grid">
                        <button
                            className="btn btn-primary">후기<br />확인</button>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col">{data.name}<span className="app-small-font text-secondary"> {data.round}회차</span></div>
                        </div>
                        <div className="row">
                            {
                                data.total_avrg_score >= 4.7 ?
                                    <div className="col app-small-font text-success">평점: {data.total_avrg_score}</div>
                                    :
                                    data.total_avrg_score <= 4.2 ?
                                        <div className="col app-small-font text-danger">평점: {data.total_avrg_score}</div>
                                        :
                                        <div className="col app-small-font text-secondary">평점: {data.total_avrg_score}</div>
                            }

                            <div className="col app-small-font text-secondary">후기 수: {data.review_cnt}</div>
                        </div>
                        <div className="row">
                            <div className="col app-small-font text-secondary">강의 시간: {data.total_training_times}시간</div>
                        </div>
                        <div className="row">
                            <div className="col app-small-font text-secondary">종강일: {data.end_date}</div>
                        </div>
                        <div className="row">
                            <div className="col app-small-font text-secondary">강사명: {teacherList}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const TeacherReviewSection = ({ centerId, teacherName }) => {

    const [roundList, setRoundList] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState({ isLoaded: false, error: null });

    const { getRoundInfoList } = useTeacherService();

    const { openModal, ReviewModal } = useReviewModal();

    useEffect(() => {
        setLoadingStatus({ isLoaded: false, error: null });
        getRoundInfoList(centerId, teacherName)
            .then((body) => {
                setRoundList(body.data.roundInfoList);
                setLoadingStatus({ isLoaded: true });
            })
            .catch((response) => {
                setLoadingStatus({ error: response });
            });
    }, [getRoundInfoList, centerId, teacherName]);

    const contentList = useMemo(() => {

        return roundList.map(
            (item, idx) => {
                return <Content data={item} key={idx} openModal={openModal} />
            }
        );

    }, [roundList, openModal]);



    return (
        <>
            <SectionHeader title="강사 후기 목록" subTitle="전체 후기를 한 눈에 볼 수 있게 모아 봤습니다" />
            <div className="row mb-1 p-2">
                <div className="col rounded shadow bg-white" >
                    <div className="row row-cols-1 row-cols-lg-2">
                        {
                            loadingStatus.error ?
                                <div className="col">서버와 통신에 실패하였습니다. 다시 시도해주시기 바랍니다.</div>
                                :
                                !loadingStatus.isLoaded ?
                                    <div className="col text-center">
                                        <LoadingSpinner />
                                    </div>
                                    :
                                    roundList.length < 1 ?
                                        <div className="col">평가가 있는 강의가 존재하지 않습니다.</div>
                                        :
                                        contentList
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ReviewModal />
                </div>
            </div>
        </>
    );
}

export default TeacherReviewSection;