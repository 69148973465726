import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../global/component/LoadingSpinner";
// import { useNavigate } from "react-router-dom";
import SectionHeader from "../../../global/component/SectionHeader";
import useCenterService from "../../../global/hook/CenterService";
import TeacherCountDonut from "./TeacherCountDonut";


const TeacherClickable = ({ data, centerId }) => {

    const navigate = useNavigate();

    return (
        <div className="col">
            <div className="row">
                <div className="col py-1 rounded">
                    <div className="row">
                        <div className="col d-grid text-center">
                            <button onClick={() => navigate(`/teacher/${centerId}/${data.name}`)} className="btn btn-outline-primary">
                                {data.name}<span className="app-small-font">({data.cnt}) </span>
                                <i className="material-icons-outlined align-bottom">
                                    open_in_new
                                </i>
                            </button>
                            {/* {data.name}<span className="app-small-font text-secondary">({data.cnt})</span> */}
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col d-grid">
                            <button onClick={() => alert("준비중")} className="btn btn-primary">상세보기</button>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}


const AiReviewAnalysisSummarySection = ({ centerId, ncsCode }) => {

    // const navigate = useNavigate();
    const [sentimentSummaryData, setSentimentSummaryData] = useState({});
    const [teacherList, setTeacherList] = useState([]);

    const [loadingStatusSentimentScore, setLoadingStatusSentimentScore] = useState({ isLoaded: false, error: null });
    const [loadingStatusTeacherList, setLoadingStatusTeacherList] = useState({ isLoaded: false, error: null });


    const { getSentimentScore, getTeacherList } = useCenterService();

    useEffect(() => {

        setLoadingStatusSentimentScore({ isLoaded: false, error: null });
        getSentimentScore(centerId, { ncsCode })
            .then((body) => {
                setSentimentSummaryData(body.data.sentimentScore);
                setLoadingStatusSentimentScore({ isLoaded: true });
            }).catch((response) => {
                setLoadingStatusSentimentScore({ error: response });
            });


        setLoadingStatusTeacherList({ isLoaded: false, error: null });
        getTeacherList(centerId, { ncsCode })
            .then((body) => {
                setTeacherList(body.data.teacherList);
                setLoadingStatusTeacherList({ isLoaded: true });
            }).catch((response) => {
                setLoadingStatusTeacherList({ error: response });
            });


    }, [centerId, ncsCode, getSentimentScore, getTeacherList]);


    return (
        <>
            <SectionHeader title="후기 AI 분석 요약" subTitle="기관 전체 후기를 AI가 분석한 결과 입니다. 강사가 아닌 학원 관계자가 포함되어 있을 수 있습니다. 후기를 꼭 확인하여 주세요." />

            <div className="col">
                <div className="row px-2">
                    <div className="col bg-white shadow rounded py-2">

                        {
                            loadingStatusSentimentScore.error ?
                                <>서버와 통신에 실패하였습니다. 다시 시도해주시기 바랍니다.</>
                                :
                                !loadingStatusSentimentScore.isLoaded ?
                                    <LoadingSpinner />
                                    :
                                    <>
                                        <div className="row mt-1">
                                            <div className="col"><span className="app-small-font text-secondary">3년간 총 후기 수 </span>{sentimentSummaryData.review_cnt}<span className="app-small-font text-secondary">회</span></div>
                                        </div>

                                        {
                                            !sentimentSummaryData.positive_score ?
                                                <div className="row mt-1">
                                                    <div className="col">AI 감정 분석이 완료되지 않았습니다.</div>
                                                </div>
                                                :
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span className="app-small-font text-secondary">긍정 </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                {
                                                                    sentimentSummaryData.positive_score * 100 > 67 ?
                                                                        <div className="col text-success">
                                                                            {(sentimentSummaryData.positive_score * 100).toFixed(1)}<span className="app-small-font text-secondary">%</span>
                                                                        </div>
                                                                        :
                                                                        <div className="col">
                                                                            {(sentimentSummaryData.positive_score * 100).toFixed(1)}<span className="app-small-font text-secondary">%</span>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span className="app-small-font text-secondary">부정 </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                {
                                                                    sentimentSummaryData.negative_score * 100 > 13 ?
                                                                        <div className="col text-danger">
                                                                            {(sentimentSummaryData.negative_score * 100).toFixed(1)}<span className="app-small-font text-secondary">%</span>
                                                                        </div>
                                                                        :
                                                                        <div className="col">
                                                                            {(sentimentSummaryData.negative_score * 100).toFixed(1)}<span className="app-small-font text-secondary">%</span>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span className="app-small-font text-secondary">혼합 </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    {(sentimentSummaryData.mixed_score * 100).toFixed(1)}<span className="app-small-font text-secondary">%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <span className="app-small-font text-secondary">기타 </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    {(sentimentSummaryData.neutral_score * 100).toFixed(1)}<span className="app-small-font text-secondary">%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </>
                        }

                        <div className="row mt-3 border-top pt-2">
                            <div className="col"><span className="app-small-font text-secondary">강사 </span></div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {

                                    loadingStatusTeacherList.error ?
                                        <>서버와 통신에 실패하였습니다. 다시 시도해주시기 바랍니다.</>
                                        :

                                        !loadingStatusTeacherList.isLoaded ?
                                            <div className="row">
                                                <div className="col text-center">
                                                    <LoadingSpinner />
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <TeacherCountDonut teacherList={teacherList} />
                                                <div className="row my-1">
                                                    <div className="col app-small-font text-secondary">
                                                        주요 강사 목록
                                                    </div>
                                                </div>
                                                <div className="row row-cols-2">
                                                    {

                                                        teacherList
                                                            .filter((item, index) => {

                                                                if (index > 7) return false;
                                                                if (item.cnt < 3) return false;

                                                                return true;
                                                            })
                                                            .map((item, index) => (
                                                                <TeacherClickable key={index} data={item} centerId={centerId} />
                                                            ))
                                                    }
                                                </div>
                                            </>
                                }
                            </div>
                        </div>


                        {/* <div className="row mt-3">
                            <div className="col app-small-font text-secondary">후기 내 강사명 언급 수</div>
                        </div>
                        <div className="row row-cols-3">
                            <div className="col text-success" onClick={() => navigate('/teacher/1')}>홍길동 <span className="app-small-font text-secondary">(54)</span></div>
                            <div className="col text-success" onClick={() => navigate('/teacher/1')}>홍길동 <span className="app-small-font text-secondary">(11)</span></div>
                            <div className="col" onClick={() => navigate('/teacher/1')}>홍길동 <span className="app-small-font text-secondary">(9)</span></div>
                            <div className="col" onClick={() => navigate('/teacher/1')}>홍길동 <span className="app-small-font text-secondary">(3)</span></div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    );
}

export default AiReviewAnalysisSummarySection;