import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import useEtcService from "../../../global/hook/EtcService,";
import useTeacherService from "../../../global/hook/TeacherService";
import useInputs from "../../../global/hook/useInputs";
import useSearchOptions from "../../../global/hook/useSearchOptions";
import LoadingBar from "../../center/component/LoadingBar";
import NoSuchCenterNotice from "../../center/component/NoSuchCenterNotice";
import TeacherSummaryForList from "../component/TeacherSummaryForList";

const TeacherListPage = () => {

    // state
    const [{ ncsCode, address, name }, setInputs, onChange] = useInputs({
        ncsCode: "",
        address: "",
        name: "",
    });
    const [ncsCodeListStatus, setNcsCodeListStatus] = useState({ isLoaded: false, error: null });
    const [teacherListStatus, setTeacherListStatus] = useState({ isLoaded: false, error: null });

    const [ncsDatas, setNcsDatas] = useState([]);
    const [teacherDatas, setTeacherDatas] = useState([]);

    // load hooks
    const { saveSearchOptionsForTeacherList, loadSearchOptionsForTeacherList } = useSearchOptions();
    const { getTeacherList } = useTeacherService();
    const { getNcsList } = useEtcService();



    const loadTeacherList = useCallback(() => {
        setTeacherListStatus({ isLoaded: false, error: null });

        getTeacherList(loadSearchOptionsForTeacherList())
            .then((body) => {
                setTeacherDatas(body.data.list);
                setTeacherListStatus({ isLoaded: true });
            })
            .catch((response) => {
                setTeacherListStatus({ error: response });
            });

    }, [getTeacherList, loadSearchOptionsForTeacherList]);

    const initNcsSelectBox = useCallback(() => {

        setNcsCodeListStatus({ isLoaded: false, error: null });
        getNcsList()
            .then((body) => {
                setNcsCodeListStatus({ isLoaded: true });
                setNcsDatas(body.data.list);
                const searchOptions = loadSearchOptionsForTeacherList();

                setInputs(searchOptions);
            })
            .catch(response => {
                setNcsCodeListStatus({ error: response });
            });

    }, [loadSearchOptionsForTeacherList, setInputs, getNcsList]);

    //event
    const handleOnClickSearchButton = useCallback((ncsCode, address, name) => {
        saveSearchOptionsForTeacherList({ ncsCode, address, name });
        loadTeacherList();
    }, [saveSearchOptionsForTeacherList, loadTeacherList]);

    useEffect(() => {
        loadTeacherList();
        initNcsSelectBox();
    }, [initNcsSelectBox, loadTeacherList]);

    const ncsOptions = useMemo(() => ncsDatas.map(
        (item, idx) => {
            return <option style={{color: "#464444"}} value={item.ncs_code} key={idx}>{item.ncs_name} ({item.cnt}회)</option>
        }
    ), [ncsDatas]);


    const handleOnKeyPress = useCallback((event, ncsCode, address, name, teacherListStatus) => {
        if (event.key === 'Enter' && (teacherListStatus.isLoaded || teacherListStatus.error)) {
            saveSearchOptionsForTeacherList({ ncsCode, address, name });
            loadTeacherList();
        }
    }, [saveSearchOptionsForTeacherList, loadTeacherList]);


    // inner components - 가볍게만 사용 할 것
    const contentItems = useMemo(() => {

        if (teacherDatas.length <= 0) {
            return <NoSuchCenterNotice />
        }

        return teacherDatas.map(
            (item, idx) => {
                return <TeacherSummaryForList hasViewButton={true} ranking={idx + 1} teacherData={item} key={idx} />
            }

        );
    }, [teacherDatas]);

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <div className="row my-3">
                                <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            <select onChange={onChange} name="ncsCode" value={ncsCode} className={"form-select" + (ncsCode ? "" : " ncscode-not-selected")}>
                                                {
                                                    ncsCodeListStatus.error ?
                                                        <option>통신 실패</option>
                                                        :
                                                        !ncsCodeListStatus.isLoaded ?
                                                            <option>로딩중...</option>
                                                            :
                                                            <>
                                                                <option value="" className="ncscode-not-selected">ncs 직무 전체 (과정 수)</option>
                                                                {ncsOptions}
                                                            </>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col">
                                            <input onKeyUp={(event) => handleOnKeyPress(event, ncsCode, address, name, teacherListStatus)} maxLength={50} onChange={onChange} name="address" value={address} className="form-control" type="text" placeholder="지역 검색, 예)송파 분당 강남" />
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col">
                                            <input onKeyUp={(event) => handleOnKeyPress(event, ncsCode, address, name, teacherListStatus)} maxLength={50} onChange={onChange} name="name" value={name} className="form-control" type="text" placeholder="강사 및 학원 검색, 예)세모 홍길동" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 d-grid ps-0">
                                    {
                                        teacherListStatus.isLoaded || teacherListStatus.error ?
                                            <button onClick={() => handleOnClickSearchButton(ncsCode, address, name)} className="btn btn-primary">검색</button>
                                            :
                                            <button className="btn btn-primary" disabled>
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                    }
                                </div>
                            </div>

                            <div className="row mt-4">
                                <h1 className="col fs-4 fw-bold text-primary">국비 강사 목록</h1>
                                {/* <div className="col">
                                    <select className="form-select form-select-sm">
                                        <option>종합 평가 정렬</option>
                                        <option>평점순 정렬</option>
                                    </select>
                                </div> */}
                            </div>

                            <div className="row">
                                <div className="col app-small-font text-secondary">여러이유로 검색되지 않은 강사분들도 숨은 고수분들 많습니다</div>
                            </div>
                            <div className="row">
                                <div className="col app-small-font text-secondary">강사가 아닌 학원 관계자가 포함되어 있을 수 있습니다</div>
                            </div>
                            <div className="row">
                                <div className="col app-small-font text-secondary">후기를 꼭 확인하여 주세요</div>
                            </div>
                            <div className="row">
                                <div className="col app-small-font text-secondary">
                                    <span>자세한 사항은 홈화면에서 </span>
                                    <span>
                                        <a target="_blank" rel="noopener noreferrer" href="https://velog.io/@binarycraft/%EC%A7%81%EB%A6%AC%EB%8B%88%EC%84%9C%EC%B2%98-%EC%82%AC%EC%9D%B4%ED%8A%B8-%EC%9D%B4%EC%9A%A9%EC%8B%9C-%EC%A3%BC%EC%9D%98-%EC%82%AC%ED%95%AD-%EC%82%AC%EC%9D%B4%ED%8A%B8-%EC%A0%95%EB%B3%B4-%ED%95%9C%EA%B3%84%EC%A0%90-%EC%88%9C%EC%9C%84-%EA%B8%B0%EC%A4%80-%EB%93%B1">사이트 이용 주의사항</a>
                                        을 확인해주시기 바랍니다
                                    </span>
                                </div>
                            </div>

                            {/* 강사 리스트 */}
                            <div className="row p-2 row-cols-1 row-cols-md-2 row-cols-xl-3">
                                {/* 기관 섹션 시작 */}
                                {
                                    teacherListStatus.error ?
                                        <div className="col">서버와 통신에 실패하였습니다. 다시 시도해주시기 바랍니다.</div>
                                        :
                                        !teacherListStatus.isLoaded ?
                                            <LoadingBar />
                                            :
                                            contentItems
                                }
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default TeacherListPage;