import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TeacherInboxButton from "../../../global/component/TeacherInboxButton";




const TeacherSummaryForList = ({ ranking, teacherData, clickable = true, hasViewButton }) => {

    const navigate = useNavigate();

    const score = useMemo(() => {

        const fixedScore = teacherData.avg_score.toFixed(2);

        let cssClass = "";

        if (fixedScore > 4.7) {
            cssClass = "text-success";
        } else if (fixedScore < 4.3) {
            cssClass = "text-danger";
        }

        return (
            <span className={`${cssClass}`}>{fixedScore}</span>
        );
    }, [teacherData]);


    const teachNameCount = useMemo(() => {
        let cssClass = "";

        if (teacherData.extracted_name_cnt >= 10) {
            cssClass = "text-success";
        }
        return (
            <span className={`${cssClass}`}>{teacherData.extracted_name_cnt}</span>
        );
    }, [teacherData]);

    const positive = useMemo(() => {
        const positiveScore = (teacherData.avg_positive_score * 100).toFixed(1);

        let cssClass = "";

        if (positiveScore > 68) {
            cssClass = "text-success";
        } else if (positiveScore < 60) {
            cssClass = "text-danger";
        }

        return (
            <span className={`${cssClass}`}>{positiveScore}</span>
        );

    }, [teacherData]);

    const negative = useMemo(() => {
        const negativeScore = (teacherData.avg_negative_score * 100).toFixed(1);

        let cssClass = "";

        if (negativeScore < 8) {
            cssClass = "text-success";
        } else if (negativeScore > 12) {
            cssClass = "text-danger";
        }

        return (
            <span className={`${cssClass}`}>{negativeScore}</span>
        );

    }, [teacherData]);

    const rank = useMemo(() => {
        if (!ranking) return null;

        return (
            <span className="app-small-font text-secondary">[{ranking}위] </span>
        );
    }, [ranking]);

    return (
        <>
            <div className="col" onClick={() => {
                if (clickable) navigate(`/teacher/${teacherData.id}/${teacherData.name}`)
            }}>
                <div className="row px-2 my-2">
                    <div className="col bg-white shadow rounded py-2">
                        <div className="row">
                            <div className="col fw-bold">
                                {rank}
                                <span>{teacherData.name}</span>
                            </div>
                            <div className="col-auto">
                                <TeacherInboxButton
                                    name={teacherData.name}
                                    centerId={teacherData.id}
                                    centerName={teacherData.center_name}
                                    tel={teacherData.tel}
                                    score={teacherData.avg_score.toFixed(2)}
                                    address={teacherData.address_base}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col">
                                <div className="row">
                                    <div className="col app-small-font text-secondary">
                                        {teacherData.center_name}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col app-small-font text-secondary">
                                        {teacherData.address_base} {teacherData.address_detail}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col app-small-font text-secondary">
                                        tel.{teacherData.tel}
                                    </div>
                                </div>

                            </div>
                        </div>

                        {
                            // 강사 요약 클릭 불가시... 학원 클릭 가능... clickable << 이거 이름 완전 잘못 지었음...
                            // 이거.. 사실.. 리스트용 or 상세용 임...
                            !clickable &&
                            <div className="row mt-2">
                                <div className="col d-grid">
                                    <button onClick={() => navigate(`/center/${teacherData.id}`)} className="btn btn-outline-primary">
                                        {teacherData.center_name}
                                        <i className="material-icons-outlined align-bottom">open_in_new</i>
                                    </button>
                                </div>
                            </div>
                        }


                        <div className="row mt-2">
                            <div className="col">기본 정보</div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">평점 </span>
                                {score}<span className="app-small-font text-secondary">점</span>
                            </div>
                            <div className="col">
                                <span className="app-small-font text-secondary">강사명 언급 </span>
                                <span>{teachNameCount}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">과정 </span>
                                <span>{teacherData.round_cnt}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                            <div className="col">
                                <span className="app-small-font text-secondary">후기 </span>
                                <span>{teacherData.review_cnt}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">담기 </span>
                                <span>{teacherData.inbox_count}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                            <div className="col">
                                <span className="app-small-font text-secondary">보기 </span>
                                <span>{teacherData.view_count}</span><span className="app-small-font text-secondary">회</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <span className="app-small-font text-secondary">평균 후기 길이 </span>
                                <span>{teacherData.avg_review_length.toFixed(0)}</span><span className="app-small-font text-secondary">자</span>
                            </div>
                        </div>

                        <div className="row mt-2 border-top pt-2">
                            <div className="col">후기 AI 분석</div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <span className="app-small-font text-secondary">긍정 </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <span>{positive}</span><span className="app-small-font">%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <span className="app-small-font text-secondary">부정 </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <span>{negative}</span><span className="app-small-font">%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <span className="app-small-font text-secondary">혼합 </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <span>{(teacherData.avg_mixed_score * 100).toFixed(1)}</span><span className="app-small-font">%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <span className="app-small-font text-secondary">기타 </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <span>{(teacherData.avg_neutral_score * 100).toFixed(1)}</span><span className="app-small-font">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {hasViewButton &&
                            <div className="row mt-2 pt-2 border-top">
                                <div className="col d-grid">
                                    <button className="btn btn-primary">상세보기</button>
                                </div>
                            </div>
                        }



                    </div>
                </div>
            </div>
        </>
    );
}

export default TeacherSummaryForList;