// 전화번호 이상한 것 다 정리해야 하나?
const TelSection = ({telNumber}) => {
    return (
        <>
            <div className="row mt-2">
                <div className="col d-grid">
                    <a className={`btn btn-primary${telNumber ? "" : " disabled"}`} href={`tel:${telNumber}`}>
                        <span style={{fontSize: "1.4em"}} className="material-icons-outlined align-text-bottom">
                            phone_enabled
                        </span>                        
                        <span> 전화 걸기</span>
                    </a>
                </div>
            </div>
        </>
    );
}

export default TelSection;