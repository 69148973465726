import { useCallback } from "react"
import useRequest from "./AppRequest";

const useEtcService = () => {
    const { get } = useRequest();

    const getNcsList = useCallback(() => {
        return get("api/v1/user/etc/ncslist");
    }, [get]);

    return {getNcsList};
}

export default useEtcService;