import { useCallback } from "react";
import useRequest from "./AppRequest";

const useTeacherService = () => {

    const { get } = useRequest();

    const getTeacherList = useCallback((payload = null) => {
        return get("api/v1/user/teacher/list", payload);
    }, [get]);


    const getTeacherSummary = useCallback((centerId, teacherName, payload = null) => {
        return get(`api/v1/user/teacher/${centerId}/${teacherName}`, payload);
    }, [get]);

    const getRoundInfoList = useCallback((centerId, teacherName, payload = null) => {
        return get(`api/v1/user/teacher/${centerId}/${teacherName}/roundInfoList`, payload);
    }, [get]);

    const increaseViewCount = useCallback((centerId, teacherName, payload = null) => {
        return get(`api/v1/user/teacher/${centerId}/${teacherName}/increaseViewCount`, payload);
    }, [get]);

    const increaseInboxCount = useCallback((centerId, teacherName, payload = null) => {
        return get(`api/v1/user/teacher/${centerId}/${teacherName}/increaseInboxCount`, payload);
    }, [get]);

    const decreaseInboxCount = useCallback((centerId, teacherName, payload = null) => {
        return get(`api/v1/user/teacher/${centerId}/${teacherName}/decreaseInboxCount`, payload);
    }, [get]);


    

    return {
        getTeacherList, 
        getTeacherSummary, 
        getRoundInfoList, 
        increaseViewCount,
        increaseInboxCount,
        decreaseInboxCount,
    };
}

export default useTeacherService;