import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function MiddleScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        // document.getElementById("app-wrapper-middle").scrollTo(0, 0);
        // window.scrollTo(0, 0);
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    }, [pathname]);

    return null;
}