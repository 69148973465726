import { useEffect, useState } from "react";
import SectionHeader from "../../../global/component/SectionHeader";
import useCenterService from "../../../global/hook/CenterService";
import CourseRatingLineGraph from "./CourseRatingLineGraph";

const RatingAnalysisSection = ({centerId, ncsCode}) => {

    const [scoreList, setScoreList] = useState([]);
    const {getLatestReviewScore} = useCenterService();

    const [scoreListStatus, setScoreListStatus] = useState({ isLoaded: false, error: null });


    useEffect(() => {

        setScoreListStatus({ isLoaded: false, error: null });

        getLatestReviewScore(centerId, {ncsCode})
            .then(body => {
                setScoreList(body.data.list);
                setScoreListStatus({ isLoaded: true });
            })
            .catch(response => {
                setScoreListStatus({ error: response });
            });     

    }, [getLatestReviewScore, centerId, ncsCode]);


    return (
        <>
            <SectionHeader title="평점 분석" subTitle="회차의 흐름에 따라 평점 변화를 눈여겨보세요" />
            <div className="row px-2">
                <div className="col">
                    <CourseRatingLineGraph loadStatus={scoreListStatus} scoreList={scoreList}></CourseRatingLineGraph>
                </div>
            </div>
        </>
    );
}

export default RatingAnalysisSection;