import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCenterService from "../../../global/hook/CenterService";
import useInputs from "../../../global/hook/useInputs";
import AiReviewAnalysisSummarySection from "../component/AiReviewAnalysisSummarySection";
import CenterBasicInformationSection from "../component/CenterBasicInformationSection";
import FortuneAnalysisSection from "../component/FortuneAnalysisSection";
// import KakaoOneClickSection from "../component/KakaoOneClickSection";
import NegativeReviewSection from "../component/NegativeReviewSection";
import PastLecturesListSection from "../component/PastLecturesListSection";
import RatingAnalysisSection from "../component/RatingAnalysisSection";
import UpcomingLecturesListSection from "../component/UpcomingLecturesListSection";
// import TelSection from "../component/TelSection";

const CenterDetailPage = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const centerId = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);

    const [{ ncsCode }, , onChange] = useInputs({
        ncsCode: "",
    });

    const [ncsCodeListStatus, setNcsCodeListStatus] = useState({ isLoaded: false, error: null });
    const [ncsDatas, setNcsDatas] = useState([]);
    const [centerName, setCenterName] = useState(null);


    const { getNcsListByCenterId, increaseViewCount, getMinimumInfo } = useCenterService();

    const loadNcsList = useCallback(() => {
        setNcsCodeListStatus({ isLoaded: false, error: null });
        getNcsListByCenterId(centerId)
            .then((body) => {
                setNcsCodeListStatus({ isLoaded: true });
                setNcsDatas(body.data.list);
            })
            .catch((response) => {
                setNcsCodeListStatus({ error: response });
            });
    }, [centerId, getNcsListByCenterId]);

    const loadCenterMinimumInfo = useCallback(() => {
        getMinimumInfo(centerId)
            .then((body) => {
                setCenterName(body.data.info.name);
            })
            .catch((response) => {
                // alert("loadCenterMinimumInfo fail");
            });
    }, [centerId, getMinimumInfo]);

    useEffect(() => {
        loadNcsList();
        loadCenterMinimumInfo();
    }, [loadNcsList, loadCenterMinimumInfo]);

    useEffect(() => {
        increaseViewCount(centerId);
    }, [centerId, increaseViewCount]);


    const ncsOptions = useMemo(() => ncsDatas.map(
        (item, idx) => {
            return <option style={{ color: "#464444" }} value={item.ncs_code} key={idx}>{item.ncs_name} ({item.cnt}회)</option>
        }
    ), [ncsDatas]);


    return (
        <>
            {/* 학원 카드 시작 */}
            <div className="row">
                <div className="col pt-5">

                    <div className="fixed-top" style={{ backgroundColor: "#f0f0f0" }}>
                        <div className="container-xxl">
                            <div className="row row-cols-1 py-2 app-bg-color shadow">
                                <div className="col fw-bold">
                                    {/* <CenterInboxButton centerId={centerId} /> */}

                                    <span style={{fontSize: "1.3em"}} className="me-2 material-icons-outlined align-text-bottom" onClick={() => navigate(-1)}>
                                        arrow_back
                                    </span>

                                    <span>
                                        {centerName}
                                    </span>

                                </div>
                                <div className="col">
                                    <span className="app-small-font text-secondary">기관에서 진행한 과정 분류 선택해서 보기</span>
                                </div>
                                <div className="col">
                                    <select onChange={onChange} name="ncsCode" value={ncsCode} className={"form-select" + (ncsCode ? "" : " ncscode-not-selected")}>
                                        {
                                            ncsCodeListStatus.error ?
                                                <option>통신 실패</option>
                                                :
                                                !ncsCodeListStatus.isLoaded ?
                                                    <option>로딩중...</option>
                                                    :
                                                    <>
                                                        <option value="" className="ncscode-not-selected">ncs 직무 전체 (과정 수)</option>
                                                        {ncsOptions}
                                                    </>
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CenterBasicInformationSection centerId={centerId} ncsCode={ncsCode} />
                    {/* <KakaoOneClickSection /> */}
                    {/* <TelSection /> */}
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <FortuneAnalysisSection centerId={centerId} ncsCode={ncsCode} />
                        </div>
                        <div className="col">
                            <RatingAnalysisSection centerId={centerId} ncsCode={ncsCode} />
                        </div>
                    </div>
                    <AiReviewAnalysisSummarySection centerId={centerId} ncsCode={ncsCode} />
                    <PastLecturesListSection centerId={centerId} ncsCode={ncsCode} />
                    {/* <ReviewWordCloudSection /> */}
                    {/* <LongestReviewForCenterSection centerId={centerId} ncsCode={ncsCode} /> */}
                    <NegativeReviewSection centerId={centerId} ncsCode={ncsCode} />
                    {/* <TeacherAnalysisForCenterSection /> */}
                    <UpcomingLecturesListSection centerId={centerId} ncsCode={ncsCode} />


                    {/* <div className="row mt-3">
                        <div className="col fw-bold fs-4 text-primary">학원 소개</div>
                    </div>

                    <div className="row">
                        <div className="col app-small-font text-secondary">bm</div>
                    </div>

                    <div className="row mt-3">
                        <div className="col fw-bold fs-4 text-primary">학원 모집 이미지</div>
                    </div>

                    <div className="row">
                        <div className="col app-small-font text-secondary">bm</div>
                    </div>

                    <div className="row mt-3">
                        <div className="col fw-bold fs-4 text-primary">학원 최신 뉴스</div>
                    </div>

                    <div className="row">
                        <div className="col app-small-font text-secondary">bm</div>
                    </div> */}

                    <div className="row">
                        <div className="col d-grid p-3">
                            <button className="btn btn-outline-primary" onClick={() => { navigate("/center") }}><span className="material-icons-outlined align-bottom">menu</span> 학원 목록</button>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}

export default CenterDetailPage;