import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MenuComponent = (props) => {

    const navigate = useNavigate()

    return (
        <div className={"col pt-1 pb-2" + (props.selected ? " app-menu-selected align-items-center" : "")} onClick={() => navigate(props.link)}>
            <div className="row">
                <div className="col">
                    <span className={"material-icons-outlined" + (props.selected ? " app-icon-fill" : "")}>{props.icon}</span>
                </div>
            </div>
            <div className="row">
                <div className="col">{props.name}</div>
            </div>
        </div>
    );
}


const BottomMenu = () => {

    const location = useLocation();

    const bottomStyle = {
        color: '#569cd6'
    };

    useEffect(() => {

        const menuButton = document.getElementById("menuButton");

        if(!menuButton) return;

        menuButton.setAttribute("onclick", `
            const bsOffcanvas = new bootstrap.Offcanvas('#offcanvasRight')
            bsOffcanvas.show()
        `);

    }, []);

    return (
        <div className="bg-white fixed-bottom">
            <div className="container-xxl px-0">
                <div className="row app-wrapper-bottom text-center bg-white app-small-font px-3" style={bottomStyle}>
                    <MenuComponent name="홈" icon="home" selected={location.pathname === "/" || location.pathname.startsWith("/home")} link="/"></MenuComponent>
                    <MenuComponent name="기관" icon="school" selected={location.pathname.startsWith("/center")} link="/center"></MenuComponent>
                    <MenuComponent name="강사" icon="person_search" selected={location.pathname.startsWith("/teacher")} link="/teacher"></MenuComponent>
                    {/* <MenuComponent name="커뮤" icon="comment" selected={location.pathname.startsWith("/community")} link="/community"></MenuComponent> */}
                    <MenuComponent name="보관" icon="archive" selected={location.pathname.startsWith("/storage-box")} link="/storage-box"></MenuComponent>
                    <MenuComponent name="통계" icon="bar_chart" selected={location.pathname.startsWith("/job-statistics")} link="/job-statistics"></MenuComponent>

                    {/* <div id="menuButton" className="col py-1">
                        <div className="row">
                            <div className="col">
                                <span className="material-icons-outlined">menu</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">메뉴</div>
                        </div>
                    </div> */}

                    {/* offcanvas */}
                    <div style={{ width: "80%" }} className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasRightLabel">메뉴</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <div className="row mt-5">
                                <div className="col text-center">
                                    <span className="material-icons-outlined" style={{ fontSize: "7em" }}>
                                        warning_amber
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col text-center">
                                    공사중
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col text-center">
                                    잡다한 메뉴들...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BottomMenu;