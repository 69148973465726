import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { setScreenSize } from "../hook/useScreenSize";

const TopGuideNavigation = () => {

    const location = useLocation();
    const htmlTitle = document.querySelector("title");
    
    const [naviAniState, setNaviAniState] = useState(false)

    useEffect(() => {
        setNaviAniState(true);
        setTimeout(setScreenSize, 200); 
    }, [location.pathname]);
    const url = location.pathname;

    let naviString = "";
    if (url === "/") {
        naviString = "홈";
        htmlTitle.innerText = "직리니서처 | 국비지원 학원 순위, 추천, 분석";
    } else if(url === "/center") {
        naviString = "훈련 기관 > 목록";
        htmlTitle.innerText = "직리니서처 | 국비지원 학원 순위, 추천, 분석 - 훈련기관 목록";
    } else if(url === "/teacher") {
        naviString = "강사 > 목록";
        htmlTitle.innerText = "직리니서처 | 국비지원 학원 순위, 추천, 분석 - 강사 목록";
    } else if(url === "/community") {
        naviString = "커뮤니티";
    } else if(url === "/storage-box") {
        naviString = "보관함 > 목록";
        htmlTitle.innerText = "직리니서처 | 국비지원 학원 순위, 추천, 분석 - 보관함";
    } else if(url === "/job-statistics") {
        naviString = "직업 통계";
    } else if(/^\/center\/+/.test(url)) {
        naviString = "훈련 기관 > 목록 > 상세";
        htmlTitle.innerText = "직리니서처 | 국비지원 학원 순위, 추천, 분석 - 훈련기관 상세";
    } else if(/^\/teacher\/+/.test(url)) {
        naviString = "강사 > 목록 > 상세";
        htmlTitle.innerText = "직리니서처 | 국비지원 학원 순위, 추천, 분석 - 강사 상세";
    } 

    return (
        <CSSTransition in={naviAniState} classNames="navi-font-ani" timeout={500} 
            // onEnter={() => console.log('onEnter')}
            // onEntering={() => console.log('onEntering')}
            onEntered={() => {
                // console.log('onEntered'); 
                setNaviAniState(false);}
            }
            // onExit={() => console.log('onExit')}
            // onExiting={() => console.log('onExiting')}
            // onExited={() => console.log('onExited')}            
        >
        <div className="row py-1">
            <div className={`col app-small-font px-4`}>{naviString}</div>
        </div>
        </CSSTransition>
    );
}

export default TopGuideNavigation;