
const NoSuchCenterNotice = () => {

    return (
        <div className="col">
            <div className="row px-2 my-2">
                <div className="col bg-white shadow rounded py-2">
                <div className="row mt-2">
                        <div className="col text-danger">결과 존재하지 않음</div>
                    </div>
                    <div className="row mt-3">
                        <div className="col text-secondary">검색 방법 안내</div>
                    </div>
                    <div className="row app-small-font mt-2">
                        <div className="col">
                            <span className="text-success">"지역 검색"</span>은 공백으로 구분이 가능합니다.
                            예를들어 "송파 강남 분당"으로 검색을 하시게 되면 훈련기관의 주소에 "강남", "송파" 혹은 "분당" 글자가 포함되면 다 나오게 됩니다.
                        </div>
                    </div>
                    <div className="row app-small-font mt-2">
                        <div className="col">
                            <span className="text-success">"기관명 검색"</span> 역시 공백으로 구분이 가능합니다.
                            예를들어 "네모 삼각"으로 검색을 하시게 되면 훈련기관 이름에 "네모" 혹은 "삼각" 글자가 포함되면 다 나오게 됩니다.
                        </div>
                    </div>
                    <div className="row app-small-font mt-2">
                        <div className="col">
                            "지역 검색"과 "기관명 검색"은 둘다 충족해야 결과가 나오게 됩니다. 예를 들어...
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NoSuchCenterNotice;