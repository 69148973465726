import { ResponsiveBar } from "@nivo/bar";

const CourseRatingHistogram = ({ loadStatus, scoreList }) => {

    // 데이터 받아서 여기서 counting 시키는게 맞다... 
    const data = [
        { score: '3.x', count: 0 },
        { score: '4.0', count: 0 },
        { score: '4.1', count: 0 },
        { score: '4.2', count: 0 },
        { score: '4.3', count: 0 },
        { score: '4.4', count: 0 },
        { score: '4.5', count: 0 },
        { score: '4.6', count: 0 },
        { score: '4.7', count: 0 },
        { score: '4.8', count: 0 },
        { score: '4.9', count: 0 },
        { score: '5.0', count: 0 },
    ];

    for (const score of scoreList) {
        if (score < 4) {
            data[0].count++;
        } else if (score < 4.1) {
            data[1].count++;
        } else if (score < 4.2) {
            data[2].count++;
        } else if (score < 4.3) {
            data[3].count++;
        } else if (score < 4.4) {
            data[4].count++;
        } else if (score < 4.5) {
            data[5].count++;
        } else if (score < 4.6) {
            data[6].count++;
        } else if (score < 4.7) {
            data[7].count++;
        } else if (score < 4.8) {
            data[8].count++;
        } else if (score < 4.9) {
            data[9].count++;
        } else if (score < 5) {
            data[10].count++;
        } else if (score >= 5) {
            data[11].count++;
        }
    }

    return (
        <>
            <div className="row">
                <div className="col bg-white rounded shadow py-2">

                    {
                        loadStatus.error ?
                            <span>로드 실패하였습니다.</span>
                            : !loadStatus.isLoaded ?
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                scoreList.length < 1 ? 
                                <span>정보가 존재하지 않습니다</span>
                                :
                                <>
                                    <div className="row mb-1">
                                        <div className="col text-center app-small-font fw-bold">최근 3년 수강 평점 누적</div>
                                    </div>
                                    <div className="row">
                                        <div className="col" style={{ height: '10em' }}>
                                            <ResponsiveBar
                                                /**
                                                 * chart에 사용될 데이터
                                                 */
                                                data={data}

                                                /**
                                                 * 표현 범위
                                                 */
                                                // layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations']}
                                                layers={['grid', 'axes', 'bars']}
                                                /**
                                                 * 종, 회 설정
                                                 */
                                                // layout="horizontal"
                                                /**
                                                 * chart에 보여질 데이터 key (측정되는 값)
                                                 */
                                                keys={['count']}
                                                /**
                                                 * keys들을 그룹화하는 index key (분류하는 값)
                                                 */
                                                indexBy="score"
                                                /**
                                                 * chart margin
                                                 */
                                                // margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                                margin={{ top: 10, right: 10, bottom: 30, left: 10 }}
                                                axisLeft={null}
                                                // /**
                                                //  * chart padding (bar간 간격)
                                                //  */
                                                // padding={0.3}
                                                // /**
                                                //  * chart 색상
                                                //  */
                                                colors={['#3e76b7']} // 커스터하여 사용할 때
                                                // colors={{ scheme: 'nivo' }} // nivo에서 제공해주는 색상 조합 사용할 때
                                                /**
                                                 * color 적용 방식
                                                 */
                                                colorBy="id" // 색상을 keys 요소들에 각각 적용
                                                // colorBy="indexValue" // indexBy로 묵인 인덱스별로 각각 적용
                                                theme={{
                                                    /**
                                                     * label style (bar에 표현되는 글씨)
                                                     */
                                                    labels: {
                                                        text: {
                                                            // fontSize: 14,
                                                            fill: '#ffffff',
                                                        },
                                                    },
                                                    /**
                                                     * legend style (default로 우측 하단에 있는 색상별 key 표시)
                                                     */
                                                    // legends: {
                                                    //     text: {
                                                    //         fontSize: 12,
                                                    //         fill: '#000000',
                                                    //     },
                                                    // },
                                                    // axis: {
                                                    //     /**
                                                    //      * axis legend style (bottom, left에 있는 글씨)
                                                    //      */
                                                    //     legend: {
                                                    //         text: {
                                                    //             fontSize: 20,
                                                    //             fill: '#000000',
                                                    //         },
                                                    //     },
                                                    //     /**
                                                    //      * axis ticks style (bottom, left에 있는 값)
                                                    //      */
                                                    //     ticks: {
                                                    //         text: {
                                                    //             fontSize: 16,
                                                    //             fill: '#000000',
                                                    //         },
                                                    //     },
                                                    // },
                                                }}
                                            // /**
                                            //  * axis bottom 설정
                                            //  */
                                            // axisBottom={{
                                            //     tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                                            //     tickPadding: 5, // tick padding
                                            //     tickRotation: 0, // tick 기울기
                                            //     legend: 'score', // bottom 글씨
                                            //     legendPosition: 'middle', // 글씨 위치
                                            //     legendOffset: 40, // 글씨와 chart간 간격
                                            // }}
                                            // /**
                                            //  * axis left 설정
                                            //  */
                                            // axisLeft={{
                                            //     tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                                            //     tickPadding: 5, // tick padding
                                            //     tickRotation: 0, // tick 기울기
                                            //     legend: 'price', // left 글씨
                                            //     legendPosition: 'middle', // 글씨 위치
                                            //     legendOffset: -60, // 글씨와 chart간 간격
                                            // }}
                                            // /**
                                            //  * label 안보이게 할 기준 width
                                            //  */
                                            // labelSkipWidth={36}
                                            // /**
                                            //  * label 안보이게 할 기준 height
                                            //  */
                                            // labelSkipHeight={12}
                                            /**
                                             * bar 클릭 이벤트
                                             */
                                            // /**
                                            //  * legend 설정 (default로 우측 하단에 있는 색상별 key 표시)
                                            //  */
                                            // legends={[
                                            //     {
                                            //         dataFrom: 'keys', // 보일 데이터 형태
                                            //         anchor: 'bottom-right', // 위치
                                            //         direction: 'column', // item 그려지는 방향
                                            //         justify: false, // 글씨, 색상간 간격 justify 적용 여부
                                            //         translateX: 120, // chart와 X 간격
                                            //         translateY: 0, // chart와 Y 간격
                                            //         itemsSpacing: 2, // item간 간격
                                            //         itemWidth: 100, // item width
                                            //         itemHeight: 20, // item height
                                            //         itemDirection: 'left-to-right', // item 내부에 그려지는 방향
                                            //         itemOpacity: 0.85, // item opacity
                                            //         symbolSize: 20, // symbol (색상 표기) 크기
                                            //         effects: [
                                            //             {
                                            //                 // 추가 효과 설정 (hover하면 item opacity 1로 변경)
                                            //                 on: 'hover',
                                            //                 style: {
                                            //                     itemOpacity: 1,
                                            //                 },
                                            //             },
                                            //         ],
                                            //         onClick: handle.legendClick, // legend 클릭 이벤트
                                            //     },
                                            // ]}
                                            />
                                        </div>
                                    </div>

                                </>
                    }



                </div>
            </div>
        </>
    );
}

export default CourseRatingHistogram;