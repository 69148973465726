import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useTeacherService from "../../../global/hook/TeacherService";
import LoadingBar from "../../center/component/LoadingBar";
import TeacherReviewSection from "../component/TeacherReviewSection";
import TeacherSummarySection from "../component/TeacherSummarySection";

const TeacherDetailPage = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const { getTeacherSummary, increaseViewCount } = useTeacherService();

    const [teacherData, setTeacherData] = useState([]);
    // const [teacherName, setTeacherName] = useState(null);
    // const [centerId, setCenterId] = useState(null);

    const values = location.pathname.split("/");
    const teacherName = values.pop();
    const centerId = values.pop();

    const [teacherStatus, setTeacherStatus] = useState({ isLoaded: false, error: null });

    const loadTeacher = useCallback((centerId, teacherName) => {
        setTeacherStatus({ isLoaded: false, error: null });

        getTeacherSummary(centerId, teacherName)
            .then((body) => {
                setTeacherData(body.data.summary);
                setTeacherStatus({ isLoaded: true });
            })
            .catch((response) => {
                setTeacherStatus({ error: response });
            });

    }, [getTeacherSummary]);


    useEffect(() => {

        loadTeacher(centerId, teacherName);

    }, [centerId, teacherName, location, loadTeacher]);

    useEffect(() => {
        increaseViewCount(centerId, teacherName);
    }, [increaseViewCount, centerId, teacherName]);


    return (
        <>
            {
                teacherStatus.error ?
                    <div className="col">서버와 통신에 실패하였습니다. 다시 시도해주시기 바랍니다.</div>
                    :
                    !teacherStatus.isLoaded ?
                        <LoadingBar />
                        :
                        <TeacherSummarySection teacherData={teacherData} clickable={false} />

            }
            {/* <TeacherRatingGraphSection /> */}
            <TeacherReviewSection centerId={centerId} teacherName={teacherName} />

            <div className="row">
                <div className="col d-grid p-3">
                    <button className="btn btn-outline-primary" onClick={() => { navigate("/teacher") }}><span className="material-icons-outlined align-bottom">menu</span> 강사 목록</button>
                </div>
            </div>
        </>
    );
}

export default TeacherDetailPage;