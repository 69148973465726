
export const setScreenSize = () => {

    // console.log("App - setScreenSize 호출");

    // const vh = window.innerHeight * 0.01;
    // document.documentElement.style.setProperty('--vh', `${vh}px`);

    // const top = document.querySelector(".app-wrapper-top");
    // const middle = document.querySelector(".app-wrapper-middle");
    // const bottom = document.querySelector(".app-wrapper-bottom");

    // const restSize = top.clientHeight + bottom.clientHeight;

    // middle.style.setProperty("height", `calc(var(--vh, 1vh) * 100 - ${restSize}px)`);

}
