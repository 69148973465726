const JobStatistics = () => {

    return (
        <>
            <div className="row mt-5">
                <div className="col text-center">
                    <span className="material-icons-outlined" style={{fontSize:"7em"}}>
                        warning_amber
                    </span>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-center">
                    공사중
                </div>
            </div>
            <div className="row mt-1">
                <div className="col text-center">
                    직업별 평점 평균, 훈련 시간 등. 기준을 위한 통계 작업
                </div>
            </div>
        </>
    );
}

export default JobStatistics;