import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import BottomMenu from './global/component/BottomMeun';
import MiddleScrollToTop from './global/component/MiddleScrollToTop';
import Top from './global/component/Top';
import ErrorBoundary from './global/error/ErrorBoundary';
import { setScreenSize } from './global/hook/useScreenSize';
import CenterDetailPage from './presentation/center/wapper/CenterDetailPage';
import CenterListPage from './presentation/center/wapper/CenterListPage';
import HomePage from './presentation/home/wrapper/HomePage';
import JobStatistics from './presentation/jobStatistics/wrapper/JobStatistics';
import StorageBoxPage from './presentation/storage/wrapper/StorageBoxPage';
import TeacherDetailPage from './presentation/teacher/wrapper/TeacherDetailPage';
import TeacherListPage from './presentation/teacher/wrapper/TeacherListPage';

function App() {

  // 거지 같은 카카오 인앱 브라우저 대응
  // 우선 여러가지 폰 테스트 이후에...

  // 기기별 크기 조절
  useEffect(() => {
    (() => {
      window.addEventListener('resize', () => {
        setScreenSize()
      });
    })();
    return () => {
      window.removeEventListener('resize', () => setScreenSize());
    };
  }, []);

  useEffect(() => {
    setTimeout(setScreenSize, 200);
  }, []);

  return (
    <BrowserRouter>
      <MiddleScrollToTop />

      <Top></Top>
      <div className="container-xxl">
        {/* 중앙 */}
        <div id="app-wrapper-middle" className="app-wrapper-middle row">
          <div className="col">
            <ErrorBoundary>
              <Routes>
                <Route path="/" exact element={<HomePage />} />
                <Route path="/center" exact element={<CenterListPage />} />
                <Route path="/center/*" exact element={<CenterDetailPage />} />
                <Route path="/teacher" exact element={<TeacherListPage />} />
                <Route path="/teacher/*" exact element={<TeacherDetailPage />} />
                <Route path="/storage-box" exact element={<StorageBoxPage />} />
                <Route path="/job-statistics" exact element={<JobStatistics />} />
              </Routes>
            </ErrorBoundary>
          </div>
        </div>
      </div>
      <BottomMenu></BottomMenu>


    </BrowserRouter>
  );
}

export default App;
