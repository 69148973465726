import SectionHeader from "../../../global/component/SectionHeader";
import TeacherSummaryForList from "./TeacherSummaryForList";

const TeacherSummarySection = ({teacherData, clickable = true}) => {
    return (
        <>
            <SectionHeader title="강사 정보 요약" subTitle="한 눈에 강사 정보를 확인해보세요" />
            <div className="row">
                <TeacherSummaryForList teacherData={teacherData} clickable={clickable} />
            </div>
        </>
    );
}

export default TeacherSummarySection;