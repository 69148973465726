import axios from "axios";
import { useCallback } from "react";

// const BASE_URL = "http://localhost:8080/";
// const BASE_URL = "http://192.168.1.110:8080/";
// const BASE_URL = "http://192.168.1.94:8080/";
// const BASE_URL = "http://home.s001lec.com:7280/";
// const BASE_URL = "http://home.null-pointer-exception.com:7280/";
const BASE_URL = "https://api-ba.binarycraft.studio/";

const useRequest = () => {

    const get = useCallback((url, payload = null) => {
        let callOption = {
            method: "get",
            url: `${BASE_URL}${url}`
        };

        if (payload) {
            callOption.params = payload;
        }

        return axios(callOption)
            .then((response) => {
                return response.data;
            });
    }, []);

    const post = useCallback((url, payload = null) => {
        let callOption = {
            method: "post",
            url: `${BASE_URL}${url}`
        };

        if (payload) {
            callOption.data = payload;
        }

        return axios(callOption)
            .then((response) => {
                return response.data;
            });
    }, []);

    return { get, post };
}

export default useRequest;