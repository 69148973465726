const SectionHeader = (props) => {

    return (
        <>
            <div className="row mt-3">
                <h1 className="col fw-bold fs-4 text-primary">{props.title}</h1>
            </div>
            <div className="row mb-1">
                <div className="col app-small-font text-secondary">{props.subTitle}</div>
            </div>
        </>
    );
}

export default SectionHeader;