const getCenterDataObjectFromStorage = () => {
    let centerInboxData = localStorage.getItem("centerInbox");
    if(!centerInboxData) {
        centerInboxData = `{"list": []}`
    };
    centerInboxData = JSON.parse(centerInboxData);

    return centerInboxData;
}

export const saveCenterDataToStorage = ({centerId, name, tel, score, address}) => {
    const obj = getCenterDataObjectFromStorage();

    if(!hasCenterDataFromStorage(centerId)){
        obj.list.push({centerId, name, tel, score, address});
        localStorage.setItem("centerInbox", JSON.stringify(obj));
    }
}

export const deleteCenterData = (centerId) => {
    const obj = getCenterDataObjectFromStorage();
    const index = obj.list.findIndex(item => item.centerId === centerId);
    obj.list.splice(index, 1);
    localStorage.setItem("centerInbox", JSON.stringify(obj));
}

export const hasCenterDataFromStorage = (centerId) => {
    const obj = getCenterDataObjectFromStorage();
    return obj.list.findIndex(item => item.centerId === centerId) !== -1 ? true : false;
}

export const getCenterDataList = () => {
    const obj = getCenterDataObjectFromStorage();
    return obj.list;
}
