import { useMemo } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteCenterData, getCenterDataList } from "../../../common/util/centerDataStorage";
import { deleteTeacherData, getTeacherDataList } from "../../../common/util/teacherDataStorage";
import SectionHeader from "../../../global/component/SectionHeader";

const CenterCard = ({ name, address, tel, centerId, score, reloadFucn }) => {

    const navigate = useNavigate();

    return (
        <div className="col my-1">
            <div className="row px-2">
                <a className="col-auto d-grid ps-0" href={`tel:${tel}`}>
                    <button className={`align-text-bottom btn btn-primary${tel ? "" : " disabled"}`} href={`tel:${tel}`}>
                        <span style={{fontSize: "1.4em"}} className="material-icons-outlined align-text-bottom">
                            phone_enabled
                        </span>                        
                    </button>
                </a>

                <div className="col bg-white shadow rounded py-2" onClick={() => navigate(`/center/${centerId}`)}>
                    <div className="row">
                        <div className="col fw-bold">{name}</div>
                        <div className="col-auto fw-bold">{(score / 20).toFixed(2)}</div>
                    </div>
                    <div className="row">
                        <div className="col app-small-font text-secondary">
                            {address}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col app-small-font text-secondary">
                            tel.{tel}
                        </div>
                    </div>
                </div>
                <div className="col-auto d-grid pe-0" onClick={(e) => {
                    e.stopPropagation();
                    deleteCenterData(centerId);
                    reloadFucn();
                }}><button className="btn btn-danger">
                        <span className="material-icons-outlined">delete</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

const TeacherCard = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="col my-1">
                <div className="row px-2">
                    <div className="col bg-white shadow rounded py-2" onClick={() => navigate(`/teacher/${props.centerId}/${props.teacherName}`)}>
                        <div className="row">
                            <div className="col fw-bold">{props.teacherName}</div>
                            <div className="col-auto fw-bold">{props.score}</div>
                        </div>
                        <div className="row">
                            <div className="col app-small-font text-secondary">
                                {props.centerName}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col app-small-font text-secondary">
                                tel.{props.tel}
                            </div>
                        </div>
                    </div>
                    <div className="col-auto d-grid pe-0" onClick={(e) => {
                        e.stopPropagation();
                        deleteTeacherData(props.teacherName, props.centerId);
                        props.reloadFucn();
                    }}>
                        <button className="btn btn-danger">
                            <span className="material-icons-outlined">delete</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}


const StorageBoxPage = () => {

    const [centerList, setCenterList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);

    const reloadCenterList = useCallback(() => {
        setCenterList(getCenterDataList());
    }, []);

    const reloadTeacherList = useCallback(() => {
        setTeacherList(getTeacherDataList());
    }, []);


    useEffect(() => {
        reloadCenterList();
        reloadTeacherList();
    }, [reloadCenterList, reloadTeacherList]);

    const teacherCardList = useMemo(() => {
        if (teacherList.length < 1) {
            return (
                <><div className="col">
                    아직 담긴 강사가 없습니다. <Link to={"/teacher"}>강사 목록</Link>에서 담아 보세요
                </div></>
            );
        }

        return teacherList
            .map(
                (item, idx) => {
                    return <TeacherCard
                        teacherName={item.teacherName}
                        centerName={item.centerName}
                        address={item.address}
                        tel={item.tel}
                        centerId={item.centerId}
                        score={item.score}
                        key={idx}
                        reloadFucn={reloadTeacherList}
                    />
                }
            )



    }, [teacherList, reloadTeacherList]);


    const centerCardList = useMemo(() => {

        if (centerList.length < 1) {
            return (
                <><div className="col">
                    아직 담긴 학원이 없습니다. <Link to={"/center"}>학원 목록</Link>에서 담아 보세요
                </div></>
            );
        }

        return centerList
            .map(
                (item, idx) => {
                    return <CenterCard
                        name={item.name}
                        address={item.address}
                        tel={item.tel}
                        centerId={item.centerId}
                        score={item.score}
                        key={idx}
                        reloadFucn={reloadCenterList}
                    />
                }
            )
    }, [centerList, reloadCenterList]);

    return (
        <>
            <div className="row">
                <div className="col">
                    <SectionHeader title="보관된 훈련 기관 목록" subTitle="모아두신 훈련기관 목록입니다" />
                    <div className="row row-cols-1 row-cols-lg-2">
                        {centerCardList}
                    </div>

                    <SectionHeader title="보관된 강사 목록" subTitle="모아두신 강사 목록입니다" />
                    <div className="row row-cols-1 row-cols-lg-2">
                        {teacherCardList}
                    </div>
                </div>
            </div>
            <pre>



















            </pre>
        </>
    );
}

export default StorageBoxPage;