import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import LoadingSpinner from "../../../global/component/LoadingSpinner";
import SectionHeader from "../../../global/component/SectionHeader";
import useCenterService from "../../../global/hook/CenterService";

const Content = ({ data }) => {

    const handleOpenNewTab = (url) => {
        window.open(url, "_blank", "noopener, noreferrer");
    };

    return (
        <div className="col py-2 border-bottom">
            <div className="row">
                <div className="col">{data.name} <span className="app-small-font text-secondary">{data.round}회차</span></div>
            </div>
            <div className="row mt-2">
                <div className="col app-small-font text-secondary">개강일: {data.start_date}</div>
                <div className="col app-small-font text-secondary">수료일: {data.end_date}</div>
            </div>
            <div className="row">
                <div className="col app-small-font text-secondary">총 강의 시간: {data.total_training_times}</div>
                <div className="col app-small-font text-secondary">모집 인원: {data.capacity}</div>
            </div>
            <div className="row mt-2">
                <div className="col d-grid"><button onClick={() => handleOpenNewTab(data.hrd_link)} className="btn btn-primary">hrd 사이트에서 보기</button></div>
            </div>
        </div>

    );
}

const UpcomingLecturesListSection = ({ centerId, ncsCode }) => {

    const { getUpcomingLectureList } = useCenterService();
    const [upcomingLectureList, setUpcomingLectureList] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState({ isLoaded: false, error: null });


    useEffect(() => {
        setLoadingStatus({ isLoaded: false, error: null });
        getUpcomingLectureList(centerId, { ncsCode })
            .then(body => {
                setUpcomingLectureList(body.data.upcomingLectureList);
                setLoadingStatus({ isLoaded: true });
            }).catch(response => {
                setLoadingStatus({ error: response });
            });
    }, [centerId, ncsCode, getUpcomingLectureList]);

    const contentList = useMemo(() =>
        upcomingLectureList.map((item, index) => <Content data={item} key={index} />)
        , [upcomingLectureList]);


    return (
        <>
            <SectionHeader title="모집 예정 강의 목록" subTitle="다음 강의들은 어떠신가요? 클릭시 hrd 사이트로 이동합니다" />
            <div className="row p-2">
                <div className="col rounded shadow bg-white">
                    <div className="row row-cols-1 row-cols-lg-2">
                        {
                            loadingStatus.error ?
                                <div className="col">서버와 통신에 실패하였습니다. 다시 시도해주시기 바랍니다.</div>
                                :
                                !loadingStatus.isLoaded ?
                                    <div className="col text-center">
                                        <LoadingSpinner />
                                    </div>
                                    :
                                    contentList
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpcomingLecturesListSection;