// import CenterSummaryForDetail from "../../../global/component/CenterSummaryForDetail";
// // import CenterSummaryForList from "../../../global/component/CenterSummaryForList";
// // import TeacherSummary from "../../../global/component/TeacherSummary";
// import TeacherSummaryForList from "../../teacher/component/TeacherSummaryForList";
// import DailyJobStatistics from "../component/DailyJobStatistics";

const TextCard = ({ title, children }) => {

    return (
        <>
            <div className="row mt-3 row-cols-1">
                <div className="col fw-bold">{title}</div>
                <div className="col">
                    {children}
                </div>
            </div>
        </>
    );
}


const HomePage = () => {

    return (
        <>
            <div className="row my-3">
                <h1 className="fs-6 col text-center fw-bold">국비 교육 학원, 강사, 순위, 통계, 후기 AI 분석</h1>
            </div>

            <TextCard title="사이트 제작 목적">
                국비 지원 직업 훈련 <u className="text-danger fw-bold fs-5">현직 학원 강사</u>가 예비 수강생이 원하는 정보를 쉽게 찾기 위해 hrd-net 사이트 정보 수집 및 수강평(후기) AI 분석하여 통계량 제공
            </TextCard>

            <TextCard title="사이트 현황">
                필드 테스트 중
            </TextCard>

            <TextCard title="개발자 블로그">
                <a target="_blank" rel="noopener noreferrer" href="https://velog.io/@binarycraft/%EA%B5%AD%EB%B9%84-%EA%B0%95%EC%82%AC%EA%B0%80-%EC%B6%94%EC%B2%9C%ED%95%98%EB%8A%94-IT-%ED%95%99%EC%9B%90">[velog] 강사가 추천하는 국비 학원</a>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://velog.io/@binarycraft/%EC%A7%81%EB%A6%AC%EB%8B%88%EC%84%9C%EC%B2%98-%EC%82%AC%EC%9D%B4%ED%8A%B8-%EC%9D%B4%EC%9A%A9%EC%8B%9C-%EC%A3%BC%EC%9D%98-%EC%82%AC%ED%95%AD-%EC%82%AC%EC%9D%B4%ED%8A%B8-%EC%A0%95%EB%B3%B4-%ED%95%9C%EA%B3%84%EC%A0%90-%EC%88%9C%EC%9C%84-%EA%B8%B0%EC%A4%80-%EB%93%B1">[velog] 사이트 이용시 주의 사항: 사이트 정보 한계점, 순위 기준 등</a>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://velog.io/@binarycraft/%EC%A7%81%EB%A6%AC%EB%8B%88%EC%84%9C%EC%B2%98-1%EC%9D%B8-%EA%B0%9C%EB%B0%9C-%ED%9B%84%EA%B8%B0-A-to-Z">[velog] 직리니서처 - 1인 개발 후기 A to Z</a>
            </TextCard>

            <TextCard title="개발자가 추천하는 사용법">
                [하위 메뉴]
                맨 아래 <span className="text-success"><u>메뉴 아이콘(
                    <span className="material-icons-outlined" style={{ fontSize: '1em' }}>school</span>
                    <span className="material-icons-outlined" style={{ fontSize: '1em' }}>person_search</span>
                    <span className="material-icons-outlined" style={{ fontSize: '1em' }}>archive</span>
                    ) 클릭 시 항상 목록</u></span>이 나옵니다.
                <br /><br />
                [목록 및 검색]
                국비 학원을 먼저 알아보고 싶으신 분들은 기관(<span className="material-icons-outlined" style={{ fontSize: '1em' }}>school</span>) 아이콘을 클릭 하시고,
                강사를 먼저 알아보고 싶으신 분들은 강사(<span className="material-icons-outlined" style={{ fontSize: '1em' }}>person_search</span>) 아이콘을 클릭 하시면 됩니다.
                순위 목록이 나오면 <span className="text-success"><u>검색</u></span>이 가능합니다. 대부분 수강하길 원하는 기술을 정하셨을텐데
                검색 전 <span className="text-danger fs-5 fw-bold"><u>꼭!! ncs 분류를 선택</u></span> 하시고 검색하시길 바랍니다.
                이후 대부분은 지역만 입력하시고 검색하시면됩니다. 지역 검색의 경우 "송파 부산 인천"과 같이 여러지역이 입력 가능합니다.
                <span className="text-success"><u>보관하기(<span className="material-icons-outlined" style={{ fontSize: '1em' }}>archive</span>) 아이콘 클릭시 보관</u></span>이 되고 보관함에서 목록을 확인 하실 수 있습니다.
                <br /><br />
                [학원 상세]
                목록에서 상세보기를 클릭하시면 상세 페이지가 나오게 됩니다.
                하나의 국비 학원에서 여러 과목을 진행하는 경우가 있기때문에 마찬가지로 <span className="text-success"><u>맨 위에 ncs 분류 선택</u></span>하시구요.
                가장 먼저 눈여겨 보셔야 될 부분은 <span className="text-success"><u>평점, 각종 그래프</u></span>, 그리고 아래 <span className="text-success"><u>각 과정들의 후기</u></span>입니다.
                특히 후기 AI 분석에 따른 <span className="text-success"><u>학원 별 강사 목록</u></span>이 나오게 되는데 후기에 많이 언급된 강사분들은 평가가 상당히 좋습니다.
                강사명 클릭 시 강사 상세 페이지로 이동합니다.
                <br /><br />
                [강사 상세]
                강사별 평점과 수강평(후기) AI 분석 결과 확인 및 수강평을 한 눈에 볼 수 있게 모아놨습니다.
                강사 상세 페이지에서 학원명을 클릭 시 국비 학원 상세 페이지로 이동합니다.
                <br />
                ※주의사항: 강사가 아닌 학원 관계자가 포함될 수 있습니다. 또한 후기를 AI 분석을 통해 추적하기 때문에 후기에 강사 이름이 없을 시 추적하지 않습니다.
                <br /><br />
                [순위 평가]
                국비 학원 평점, 강의 횟수, AI 감정 분석, 후기 내 강사명 언급 횟수 등 각 요소에 적절한 가중치를 적용해서 순위를 평가했습니다. 차후 지속적으로 알고리즘 강화 예정입니다.
            </TextCard>

            <TextCard title="사이트 이용자 요구사항 정의">
                <ul className="m-0">
                    <li>좋은 훈련 기관(국비 학원)을 어떻게 찾을 수 있을까?</li>
                    <li>강사가 중요하다는데 좋은 강사를 어떻게 찾을 수 있나?</li>
                    <li>한 눈에 후기를 볼 수 있을까?</li>
                    <li>어떤 직업들이 있으며 관련 통계는 어떠한가?</li>
                </ul>
            </TextCard>

            <TextCard title="주요 기능">
                <ul className="m-0">
                    <li>직업 훈련 기관(국비 학원) 순위, 추천</li>
                    <li>직업 훈련 교강사 순위, 추천</li>
                    <li>수강 후기 AI 분석 및 모아보기</li>
                    <li>직업별 통계</li>
                </ul>
            </TextCard>

            <TextCard title="사이트 구조">
                ┌ 홈<br />
                ├ 기관(학원) 목록<br />
                │ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;└─ 상세<br />
                ├ 강사 목록<br />
                │ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;└─ 상세<br />
                ├ 직업 통계<br />
                └ 기타 메뉴
            </TextCard>

            <TextCard title="향후 계획">
                <ul className="m-0">
                    <li>홈 화면 개편</li>
                    <li>직업별 통계</li>
                    <li>기타 사이트 고도화</li>
                </ul>
            </TextCard>

            <TextCard title="개발자의 말">
                주요 기능 필드테스트중이며, 서버의 퍼포먼스를 측정 중에 있습니다.
                서버 확장을 위해 지속적으로 모니터링하고 있습니다. 불편한 사항 및 문의사항 있으시면 언제든지
                아래 이메일로 연락주시면 감사드리겠습니다. 예비 수강생 여러분들의 밝은 미래를 항상 응원하겠습니다.

                <br /><br />
                binarycraft.studio@gmail.com
                <br /><br />

            </TextCard>

            <TextCard title="업데이트 로그">
                <ul className="mb-0 text-secondary" style={{fontSize: "0.8em"}}>
                    <li>(2024-09-27) HRD-Net, 고용24 통합 대응</li>
                    <li>(2024-02-11) 훈련기관 전화걸기 기능 추가</li>
                    <li>(2024-02-11) 훈련기관 상세보기에서 뒤로가기 버튼 추가</li>
                    <li>(2023-10-29) 검색 초기값 "응용SW엔지니어링"으로 변경</li>
                </ul>

                <div id="updateLogAccordion">
                    <div className="accordion-item">
                        <div className="accordion-header">
                            <div className="px-2 text-primary" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                업데이트 로그 더보기
                            </div>
                        </div>
                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#updateLogAccordion">
                            <div className="accordion-body text-secondary" style={{fontSize: "0.8em"}}>
                                <ul>
                                    <li>(2023-10-29) 훈련기관 순위 점수 산정 기준 추가
                                        <ul>
                                            <li>기관별 수강평 50회 이하일 경우 순위 점수 패널티 부여</li>
                                        </ul>
                                    </li>
                                    <li>(2023-08-27) 보안 강화 및 UI 수정</li>
                                    <li>(2023-06-30) 강사 리스트 안내 문구 수정 및 버그 수정</li>
                                    <li>(2023-05-06) 버그 및 UI 수정</li>
                                    <li>(2023-04-15) 강사 검색 제한 적용</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </TextCard>

            <TextCard title="감사한 분들">
                처음 이 아이디어를 얘기해준 학생분에게 감사합니다. 항상 행복하게 지내시길 바랄께요. 
                테스트를 진행해주시고 피드백해주신분들 그리고 감수를 해주신 강사님께도 감사드립니다.
                <br/><br/>
            </TextCard>


        </>
    );
}

export default HomePage;