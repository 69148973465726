const getTeacherDataObjectFromStorage = () => {
    let teacherInboxData = localStorage.getItem("teacherInbox");
    if(!teacherInboxData) {
        teacherInboxData = `{"list": []}`
    };
    teacherInboxData = JSON.parse(teacherInboxData);

    return teacherInboxData;
}

export const saveTeacherDataToStorage = ({teacherName, centerId, centerName, tel, score, address}) => {
    const obj = getTeacherDataObjectFromStorage();
    if(!hasTeacherDataFromStorage(teacherName, centerId)){
        obj.list.push({teacherName, centerId, centerName, tel, score, address});
        localStorage.setItem("teacherInbox", JSON.stringify(obj));
    }
}

export const deleteTeacherData = (teacherName, centerId) => {
    const obj = getTeacherDataObjectFromStorage();
    const index = obj.list.findIndex(item => item.centerId === centerId && item.teacherName === teacherName);
    obj.list.splice(index, 1);
    localStorage.setItem("teacherInbox", JSON.stringify(obj));
}

export const hasTeacherDataFromStorage = (teacherName, centerId) => {
    const obj = getTeacherDataObjectFromStorage();
    return obj.list.findIndex(item => item.centerId === centerId && item.teacherName === teacherName) !== -1 ? true : false;
}

export const getTeacherDataList = () => {
    const obj = getTeacherDataObjectFromStorage();
    return obj.list;
}
