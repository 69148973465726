import { useCallback } from "react";

const useSearchOptions = () => {
    //set, get api 구성, 스토리지 활용 할 것
    //ncsCode, address, centerName
    // useInput 여기서... saveStorage.. 추가.. useIntup 초기값 loadStorage...

    // for center list 
    const saveSearchOptions = useCallback(({ ncsCode = null, address = null, centerName = null, isHardSearch = null, isRunningOnly = null }) => {

        if (ncsCode != null) localStorage.setItem("searchOptionNcsCodeForCenterList", ncsCode);
        if (isHardSearch != null) localStorage.setItem("searchOptionIsHardSearchForCenterList", isHardSearch);
        if (address != null) localStorage.setItem("searchOptionAddressForCenterList", address);
        if (centerName != null) localStorage.setItem("searchOptionCenterNameForCenterList", centerName);
        if (isRunningOnly != null) localStorage.setItem("searchOptionIsRunningOnlyForCenterList", isRunningOnly);

    }, []);

    const loadSearchOptions = useCallback(() => {

        const ncsCode = localStorage.getItem("searchOptionNcsCodeForCenterList");
        // const isHardSearch = localStorage.getItem("searchOptionIsHardSearchForCenterList");
        const address = localStorage.getItem("searchOptionAddressForCenterList");
        const centerName = localStorage.getItem("searchOptionCenterNameForCenterList");
        const isRunningOnly = localStorage.getItem("searchOptionIsRunningOnlyForCenterList");

        return {
            ncsCode: ncsCode ? ncsCode : "20010202",
            // isHardSearch: isHardSearch === "false" ? false : true,
            isHardSearch: true,
            address: address ? address : "",
            centerName: centerName ? centerName : "",
            isRunningOnly: isRunningOnly === "false" ? false : true,
        }
    }, []);

    // for teacher list 
    const saveSearchOptionsForTeacherList = useCallback(({ ncsCode = null, address = null, name = null }) => {

        if (ncsCode != null) localStorage.setItem("searchOptionNcsCodeForTeacherList", ncsCode);
        if (address != null) localStorage.setItem("searchOptionAddressForTeacherList", address);
        if (name != null) localStorage.setItem("searchOptionNameForTeacherList", name);

    }, []);


    const loadSearchOptionsForTeacherList = useCallback(() => {

        const ncsCode = localStorage.getItem("searchOptionNcsCodeForTeacherList");
        const address = localStorage.getItem("searchOptionAddressForTeacherList");
        const name = localStorage.getItem("searchOptionNameForTeacherList");

        return {
            ncsCode: ncsCode ? ncsCode : "20010202",
            address: address ? address : "",
            name: name ? name : "",
        }
    }, []);


    return { saveSearchOptions, loadSearchOptions, saveSearchOptionsForTeacherList, loadSearchOptionsForTeacherList };
}

export default useSearchOptions;